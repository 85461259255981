export type FeedType = {
  id: number;
  last_retrieve?: string;
  name: string;
  new_messages: number;
  new_sources: boolean;
  pre_defined: boolean;
  source_groups: [];
  sources: SourceType[];
  words: string;
};

export type SourceType = {
  language?: string;
  store_days?: number;
  active?: boolean;
  msg_count?: number;
  name?: string;

  id: number;
  link: string;
  type: string;
  value?: number;
  description?: string;
  internal_id?: number;
};

export type SourceGroupsType = {
  id: number;
  name: string;
};

export enum Equality {
  strictEqual = "strictEqual",
  equal = "equal",
  notEqual = "notEqual",
}

export type KeywordOptionType = {
  title: string;
  value: number;
  type: Equality;
};

export type KeywordType = {
  name: string;
  type: Equality;
};

export type MessageType = {
  blacklist: boolean;
  date: string;
  duplicate_id: any;
  duplicates_count: number;
  duplicates_sources_count: number;
  favorites: boolean;
  files: { file: string }[];
  id: number;
  internal_id: string;
  meta?: {
    preview?: PreviewType;
  };
  new: boolean;
  source: SourceType;
  text: string;
  feed_set?: FeedInMessageType[];
};

export type FeedInMessageType = {
  id: number;
  last_retrieve: string;
  name: string;
  new_messages: number;
  pre_defined: boolean;
  words: string;
};

export type PreviewType = {
  author: any;
  description: string;
  display_url: string;
  embed_height: any;
  embed_type: any;
  embed_url: any;
  embed_width: any;
  site_name: string;
  title: string;
  url: string;
};

export type SourcesInfoType = {
  available_sources: number;
  purchased_sources: number;
  used_sources: number;
};

export type VideoType = {
  preview: string;
  url: string;
};
