import React, { Component } from "react";
import Page from "../../ui/Page";
import { Flex, Box } from "@semcore/ui/flex-box";
import { Text } from "@semcore/typography";
import { SideBar, TableLoader } from "../../components";
import FeedTab from "./feedTab/FeedTab";
import SourcesTab from "./sourcesTab/SourcesTab";
import FavouritesTab from "./favouritesTab/FavouritesTab";
import { TabsKeys } from "./mainPageTypes";
import { appStore } from "../../stores";
import { inject, observer } from "mobx-react";
import { FeedType } from "../../utils/types";
import style from "./style.module.scss";
import { toJS } from "mobx";
import HomeXS from "@semcore/icon/lib/Home/xs";

interface IPage {
  history: any;
  match: any;
  appStore: typeof appStore;
}

type State = {
  currentTab: TabsKeys;
  feed?: FeedType;
  dataReady: boolean;
};

@inject("appStore")
@observer
export default class MainPage extends Component<IPage, State> {
  state: State = {
    currentTab: this.props.match.params.tabKey,
    feed: undefined,
    dataReady: false,
  };

  componentDidMount() {
    const { getAllFeeds, getAllSource, getSourceGroup, feedsLoading, feeds, getAllFavoriteMessages, getSourceInfo } =
      this.props.appStore;
    // console.log(this.props.match.params);
    if (feedsLoading) {
      Promise.all([getAllFeeds(), getAllSource(), getSourceGroup(), getAllFavoriteMessages(), getSourceInfo()]).then(
        () => {
          this.setState({
            feed: this.props.appStore.feeds.find((feed) => feed.id === +this.props.match.params.feedId),
            dataReady: true,
            currentTab: this.props.match.params.tabKey,
          });
        }
      );
    } else {
      this.setState({
        feed: feeds.find((feed) => feed.id === +this.props.match.params.feedId),
        currentTab: this.props.match.params.tabKey,
        dataReady: true,
      });
    }
  }

  feedEditCallback = () => {
    const { feeds } = this.props.appStore;
    this.setState({ feed: feeds.find((feed: FeedType) => feed.id === this.state.feed?.id) });
  };

  changeTabCallback = (tab: TabsKeys, feed?: FeedType) => {
    this.props.history.push(`/Main/${tab}/${feed ? feed.id : null}`);
    this.setState({ currentTab: tab, feed });
    // if (tab === TabsKeys.feeds) {
    //   this.props.appStore.getAllFeeds();
    // }
  };

  feedDeleteCallback = () => {
    this.props.history.push(`/Main/${TabsKeys.feeds}/${null}`);
    this.setState({ feed: undefined });
  };

  render() {
    const { currentTab, feed, dataReady } = this.state;
    const {} = this.props.appStore;
    return (
      <Page>
        <div id={"heightControl"} className={style.nonScroll}>
          <Box mt={3} mb={4}>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: 130,
                height: 30,
              }}
              onClick={() => this.props.history.replace("/Start")}>
              <HomeXS color={"#757575"} style={{ marginRight: 4 }} />
              <Text className={style.title} fontSize={"12px"} color={"#757575"} lineHeight={"18px"} fontWeight={400}>
                {"Go to main page"}
              </Text>
            </div>
          </Box>
          <Flex>
            {!dataReady && <TableLoader />}
            <Box w={"209px"}>
              <SideBar
                feedId={+this.props.match.params.feedId}
                currentTab={currentTab}
                changeTabCallback={this.changeTabCallback}
              />
            </Box>
            <Box flex={1} ml={6} mb={10}>
              {currentTab === TabsKeys.sources && <SourcesTab />}
              {currentTab === TabsKeys.feeds && dataReady && (
                <FeedTab
                  feed={feed}
                  feedDeleteCallback={this.feedDeleteCallback}
                  feedEditCallback={this.feedEditCallback}
                />
              )}
              {currentTab === TabsKeys.favourites && <FavouritesTab />}
            </Box>
          </Flex>
        </div>
      </Page>
    );
  }
}
