import React, { AbstractView, Component } from "react";
import Spin from "@semcore/spin";
import Page from "../../ui/Page";
import Script from "react-load-script";
import Api from "../../api/network";
import { inject, observer } from "mobx-react";

@inject("appStore")
@observer
class LoadingPage extends Component {
  componentDidMount() {
    // setTimeout(() => {
    //     this.props.history.replace('/Start');
    // }, 2000);
  }

  onLoad = () => {
    // console.log("load");
    window.SM.init()
      .then(() => {
        // console.log("init");
        this.props.appStore.resizeWindow();

        new Api()
          .getToken()
          .then(() => {
            this.props.history.replace("/Start");
          })
          .catch(() => {
            // console.log("withMistake");
            this.props.history.replace("/Start");
          });
      })
      .catch((error) => {
        // console.log("initError", error);
      });
  };

  onError = () => {
    // console.log("error");
  };
  onCreate = () => {
    // console.log("create");
  };

  render() {
    return (
      <Page style={{ display: "flex", justifyContent: "center" }}>
        <Script
          url="https://static.semrush.com/app-center/sdk.js"
          onCreate={this.onCreate}
          onError={this.onError}
          onLoad={this.onLoad}
        />
        <Spin size={"xxl"} center />
      </Page>
    );
  }
}

export default LoadingPage;
