import React from "react";
import Spin from "@semcore/spin";

export const TableLoader = () => {
  return (
    <div
      style={{
        zIndex: 100,
        top: 0,
        left: 0,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        // alignItems: 'center',
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255,255,255, 0.6)",
      }}>
      <Spin size={"xxl"} center mt={10} />
    </div>
  );
};
