import React, { Component } from "react";
import FullscreenModal from "@semcore/fullscreen-modal";
import Button from "@semcore/button";
import { Flex, Box } from "@semcore/ui/flex-box";
import { Text } from "@semcore/typography";
import Select from "@semcore/select";

const options = Array(6)
  .fill("")
  .map((_, index) => ({
    value: index, // value of the selected option
    label: `Label ${index}`, // the value displayed in the trigger when the option is selected
    children: `Option ${index}`, // option's children displayed in the dropdown
  }));

interface IModal {
  hidden: boolean;
  changeHiddenModalCallback: (hidden: boolean) => void;
  toolName: string;
}

class AddSourceToFeedModal extends Component<IModal> {
  componentDidUpdate(prevProps: IModal) {
    if (prevProps.hidden === true && this.props.hidden === false) {
      // this.props.appStore.openModal(true);
    }
  }

  render() {
    const { toolName, hidden, changeHiddenModalCallback } = this.props;
    return (
      <FullscreenModal visible={!hidden} onClose={() => changeHiddenModalCallback(true)}>
        <FullscreenModal.Close />
        <FullscreenModal.Back>{`Go back`}</FullscreenModal.Back>
        <FullscreenModal.Header title="Add source to existing feed" />
        <FullscreenModal.Body>
          <Box flex={1}>
            <Flex justifyContent={"center"} flex={1}>
              <Box w={"403px"} pt={8}>
                <Text color={"#333333"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"}>
                  {"Telegram Link"}
                </Text>
                <Flex mt={2}>
                  <Select options={options} placeholder="Select Feed" size="l" flex={1} />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </FullscreenModal.Body>
        <FullscreenModal.Footer>
          <Flex justifyContent={"center"} alignItems={"center"} flex={1} h={"56px"}>
            <Button size="l" theme={undefined} use="primary" mr={3}>
              Save Feed
            </Button>
            <Button size="l" theme={undefined} use="secondary">
              Cancel
            </Button>
          </Flex>
        </FullscreenModal.Footer>
      </FullscreenModal>
    );
  }
}
export default AddSourceToFeedModal;
