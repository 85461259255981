import React, { FC } from "react";
import { Flex, Box } from "@semcore/ui/flex-box";
import { Text } from "@semcore/typography";
import Card from "@semcore/ui/card";
import Button from "@semcore/ui/button";
import Link from "@semcore/ui/link";
import FavoriteS from "@semcore/icon/lib/Favorite/s";
import CopyXS from "@semcore/icon/lib/Copy/xs";
import LinkExternalXS from "@semcore/icon/lib/LinkExternal/xs";
import Skeleton from "@semcore/skeleton";

interface IFeedCard {}

export const FeedCardSkeleton: FC<IFeedCard> = ({}) => {
  return (
    <Card p={6} pt={5} pb={5} pr={5}>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Text mr={2}>
          <Skeleton height={"15px"}>
            <Skeleton.Text y="6" width="80%" />
          </Skeleton>
        </Text>
      </Flex>
      <Text mt={2} color={"#757575"} fontSize={"14px"} fontWeight={400}>
        <Skeleton height={"15px"}>
          <Skeleton.Text y="6" width="80%" />
        </Skeleton>
        <Skeleton height={"15px"}>
          <Skeleton.Text y="6" width="80%" />
        </Skeleton>
        <Skeleton height={"15px"}>
          <Skeleton.Text y="6" width="80%" />
        </Skeleton>
      </Text>
      <Flex alignItems={"center"} mt={6}>
        <Skeleton height={"15px"}>
          <Skeleton.Text y="6" width="80%" />
        </Skeleton>
      </Flex>
      <Flex justifyContent={"flex-end"} mt={2}>
        <Skeleton height={"15px"}>
          <Skeleton.Text y="6" width="80%" />
        </Skeleton>
      </Flex>
    </Card>
  );
};
