import React, { FC, ReactNode, useState } from "react";
import { Box } from "@semcore/ui/flex-box";
import Badge from "@semcore/ui/badge";
import style from "./style.module.scss";
import ChevronDownXS from "@semcore/icon/lib/ChevronDown/xs";
import ChevronUpXS from "@semcore/icon/lib/ChevronUp/xs";
import MathPlusXS from "@semcore/icon/lib/MathPlus/xs";
import { TabsKeys } from "../../pages/main/mainPageTypes";
import { CreateFeedModal } from "../../modals";
import { observer } from "mobx-react";
// import { appStore } from '../../stores';
import { FeedType } from "../../utils/types";
import { appStore } from "../../stores";
import ScrollArea from "@semcore/scroll-area";

interface IMenuItem {
  title: string;
  onClick: () => void;
  active: boolean;
}

const MenuItem: FC<IMenuItem> = ({ title, onClick, active }) => {
  return (
    <div onClick={onClick} className={`${style.menuItem} ${style.nonCopy} ${active && style.menuItemActive}`}>
      {title}
    </div>
  );
};

interface ICollapseMenuItem {
  title: string;
  children: ReactNode;
  open: boolean;
  onClick: () => void;
  childrenLenght: number;
}

const CollapseMenuItem: FC<ICollapseMenuItem> = ({ onClick, title, children, open, childrenLenght }) => {
  return (
    <div className={`${style.collapcseItemCont} ${open ? style.collapcseItemContOpen : style.collapcseItemContClose}`}>
      <div onClick={onClick} className={`${style.collapcseItemContTitle} ${style.nonCopy}`}>
        {title}
        {open ? <ChevronUpXS /> : <ChevronDownXS />}
      </div>
      <ScrollArea
        className={`${childrenLenght > 15 && style.childrenContainer} ${!open && style.childrenContainerClose}`}>
        {/* <div className={`${childrenLenght > 15 && style.childrenContainer} ${!open && style.childrenContainerClose}`}> */}
        {children}
        {/* </div> */}
      </ScrollArea>
    </div>
  );
};

interface ICollapseMenuItemElement {
  title: string;
  onClick: () => void;
  active: boolean;
  badgeCount: number;
}

const CollapseMenuItemElement: FC<ICollapseMenuItemElement> = ({ title, onClick, active, badgeCount }) => {
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
      className={`${style.collapseMenuItemElement} ${active && style.collapseMenuItemElementActive}`}>
      <span
        className={`${style.collapseMenuItemElementTitle} ${active && style.collapseMenuItemElementTitleActive} ${
          style.nonCopy
        }`}>
        {title}
      </span>
      {badgeCount > 0 && (
        <Badge bg="orange" color="white" ml={"10px"} mt={"2px"}>
          {badgeCount}
        </Badge>
      )}
    </div>
  );
};

interface ISideBar {
  changeTabCallback: (tab: TabsKeys, feed?: FeedType) => void;
  currentTab: TabsKeys;
  feedId: number | null;
}

export const SideBar: FC<ISideBar> = observer(({ changeTabCallback, currentTab, feedId }) => {
  const [open, setOpen] = useState<boolean>(true);
  const [hiddenModal, changeHiddenModal] = useState<boolean>(true);
  const [choosedFeed, setChoosedFeed] = useState<null | number>(feedId);

  const chooseFeed = (feed?: FeedType) => {
    setChoosedFeed(feed ? feed.id : null);
    changeTabCallback(TabsKeys.feeds, feed);
  };

  return (
    <Box>
      <MenuItem
        active={currentTab === TabsKeys.sources}
        onClick={() => {
          chooseFeed();
          changeTabCallback(TabsKeys.sources);
        }}
        title={"CHANNELS"}
      />
      <CollapseMenuItem
        onClick={() => setOpen(!open)}
        open={open}
        title={"FEEDS"}
        childrenLenght={appStore.feeds.length}>
        <div onClick={() => changeHiddenModal(false)} className={`${style.createButton}`}>
          <MathPlusXS mr={2} />
          {"Create new feed"}
        </div>
        {JSON.parse(JSON.stringify(appStore.feeds))
          .sort((a: FeedType, b: FeedType) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            } else {
              return -1;
            }
          })
          .map((feed: FeedType, index: number) => (
            <CollapseMenuItemElement
              key={feed.id + "feed" + index}
              onClick={() => {
                chooseFeed(feed);
                changeTabCallback(TabsKeys.feeds, feed);
              }}
              active={feed.id === choosedFeed}
              badgeCount={feed.new_messages}
              title={feed.name}
            />
          ))}
      </CollapseMenuItem>
      <MenuItem
        onClick={() => {
          chooseFeed();
          changeTabCallback(TabsKeys.favourites);
        }}
        active={currentTab === TabsKeys.favourites}
        title={"FAVOURITES"}
      />
      <CreateFeedModal
        toolName={"toolName"}
        hidden={hiddenModal}
        changeHiddenModalCallback={() => changeHiddenModal(true)}
      />
    </Box>
  );
});
