import React, { Component } from "react";
import { Flex, Box } from "@semcore/ui/flex-box";
import { Text } from "@semcore/typography";
import Card from "@semcore/ui/card";
import Button from "@semcore/ui/button";
import style from "./style.module.scss";
import EditS from "@semcore/icon/lib/Edit/s";
import TrashS from "@semcore/icon/lib/Trash/s";
import { DateRangePicker } from "@semcore/date-picker";
import StackGrid from "react-stack-grid";
import { FeedCard, FeedCardSkeleton, TableLoader } from "../../../components";
import { EditFeedModal } from "../../../modals";
import { Equality, FeedInMessageType, FeedType, MessageType, VideoType } from "../../../utils/types";
import { inject, observer } from "mobx-react";
import { addMessageToFavorite, deleteFeed, deleteMessageFromFavorite, getAllFeedsMessages } from "../../../api/request";
import { appStore } from "../../../stores";
import { NoData } from "@semcore/widget-empty";
import Pagination from "@semcore/pagination";
import moment from "moment";
import { startUrl } from "../../../utils/constants";
import { KeywordType } from "../../../utils/types";
import Modal from "@semcore/modal";
import SpinContainer from "@semcore/spin-container";
import Sticky from "@semcore/sticky";
import Spin from "@semcore/spin";
import ArrowLeftM from "@semcore/icon/lib/ArrowLeft/m";
import ArrowRightM from "@semcore/icon/lib/ArrowRight/m";
import ReactPlayer from "react-player";

interface ITab {
  feed?: FeedType;
  appStore?: typeof appStore;
  feedDeleteCallback: () => void;
  feedEditCallback: () => void;
}

type State = {
  sending: boolean;
  hiddenEditFeedModal: boolean;
  loading: boolean;
  messages: MessageType[];
  currentPage: number;
  dateRange: Date[];
  offset: number;
  count: number;
  confirmModalVisible: boolean;
  imageModalVisible: boolean;
  videoModalVisible: boolean;
  images: string[];
  videoUrl: string;
  currentImage: number;
};

@inject("appStore")
@observer
class FeedTab extends Component<ITab, State> {
  state = {
    hiddenEditFeedModal: true,
    sending: false,
    loading: false,
    messages: [],
    currentPage: 1,
    dateRange: [],
    offset: 0,
    count: 0,
    confirmModalVisible: false,
    imageModalVisible: false,
    videoModalVisible: false,
    images: [],
    currentImage: 0,
    videoUrl: "",
  };

  componentDidMount() {
    const { feed } = this.props;
    if (feed) {
      if (!this.state.loading) {
        this.setState({ loading: true }, () => {
          this.getAllMessages()?.finally(() => {
            this.props?.appStore?.getAllFeeds();
          });
        });
      } else {
        this.getAllMessages()?.finally(() => {
          this.props?.appStore?.getAllFeeds();
        });
      }
    }
  }

  componentDidUpdate(prevProps: ITab) {
    if (this.props.feed && this.props.feed.id !== prevProps.feed?.id) {
      if (!this.state.loading) {
        this.setState({ loading: true }, () => {
          this.getAllMessages()?.finally(() => {
            this.props?.appStore?.getAllFeeds();
          });
        });
      } else {
        this.getAllMessages()?.finally(() => {
          this.props?.appStore?.getAllFeeds();
        });
      }
    }
  }

  editFeedCallback = () => {
    this.setState({ sending: true }, () => {
      this.props.appStore?.getAllFeeds().finally(() => {
        this.props.feedEditCallback();
      });
      this.getAllMessages();
    });
  };

  getAllMessages = () => {
    const { feed } = this.props;
    const { offset, dateRange } = this.state;
    if (feed) {
      return new Promise((resolve, reject) => {
        getAllFeedsMessages(feed.id, offset * 50, dateRange)
          .then((json) => {
            this.setState({ loading: false, messages: json.results, count: json.count, sending: false });
            // console.log("getAllMessagesSuccess", json);
            resolve(json);
          })
          .catch((error) => {
            this.setState({ loading: false, sending: false });
            // console.log("getAllMessagesError", error);
            reject();
          });
      });
    }
  };

  changeHiddenEditFeedModal = (hidden: boolean) => {
    this.setState({ hiddenEditFeedModal: hidden });
  };

  deleteFeed = () => {
    this.setState({ sending: true }, () => {
      if (this.props.feed != undefined) {
        deleteFeed(this.props.feed?.id)
          .then((json) => {
            // console.log("deleteFeedSuccess", json);
            this.props.appStore?.getAllFeeds().finally(() => {
              this.props.feedDeleteCallback();
              this.setState({ sending: false, messages: [] });
            });
          })
          .catch((error) => {
            // console.log("deleteFeedError", error);
            this.setState({ sending: false });
          });
      }
    });
  };

  onCurrentPageChange = (pageNumber: number) => {
    // console.log(pageNumber);
    this.setState({ offset: pageNumber - 1, sending: true }, () => {
      this.getAllMessages();
      window.scrollTo(0, 0);
    });
  };

  addMessageToFavorite = (messageId: number) => {
    addMessageToFavorite(messageId)
      .then(() => {
        this.getAllMessages();
      })
      .catch(() => {});
  };

  deleteMessageFromFavorite = (messageId: number) => {
    deleteMessageFromFavorite(messageId)
      .then(() => {
        this.getAllMessages();
      })
      .catch(() => {});
  };

  changeRange = (dateRange: Date[]) => {
    // console.log("dateRange", dateRange);
    this.setState({ dateRange, sending: true }, () => {
      this.getAllMessages();
    });
  };

  findAndSelectionKeyword = (message: MessageType) => {
    const { feed } = this.props;
    let text: string = " " + message.text + " ";

    [
      ...text.matchAll(
        /\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]/gm
      ),
    ].forEach((matchArray: RegExpMatchArray) => {
      // console.log(matchArray);
      // console.log(matchArray[0]);
      if (matchArray.index) text = text.slice(0, matchArray.index + 2) + " " + text.slice(matchArray.index + 2);
      // if (matchArray.index) text = text.substring(0, matchArray.index) + "lol" + text.substring(matchArray.index);
    });

    let keywords: KeywordType[] | undefined = message?.feed_set
      ?.find((feedInMessage: FeedInMessageType) => feedInMessage.id === feed?.id)
      ?.words.split("\r\n")
      // .map((word) => word.replaceAll('"', ""))
      .map((word: string) => {
        switch (word[0]) {
          case '"':
            return {
              name: word.replaceAll('"', ""),
              type: Equality.strictEqual,
            };
          case "!":
            return {
              name: word,
              type: Equality.notEqual,
            };
          default:
            return {
              name: word,
              type: Equality.equal,
            };
        }
      });

    // console.log(keywords);

    if (keywords && keywords.length > 0) {
      keywords.forEach((keyword: KeywordType) => {
        switch (keyword.type) {
          case Equality.strictEqual:
            text = text.replaceAll(
              ` ${keyword.name} `,
              ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b> `
            );
            text = text.replaceAll(
              ` ${keyword.name}.`,
              ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b>.`
            );
            text = text.replaceAll(
              ` ${keyword.name}?`,
              ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b>?`
            );
            text = text.replaceAll(
              ` ${keyword.name},`,
              ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b>,`
            );
            text = text.replaceAll(
              ` ${keyword.name}!`,
              ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b>!`
            );
            break;
          case Equality.equal:
            text = text.replaceAll(
              `${keyword.name}`,
              `<b style="background-color: rgba(79, 174, 51, 0.15);color: #4FAE33">${keyword.name}</b>`
            );
            break;
          case Equality.notEqual:
            text = text.replaceAll(
              `${keyword.name}`,
              `<b style="background-color: rgba(255, 127, 0, 0.15);color: #FF7F00">${keyword.name}</b>`
            );
            break;
        }
      });
    }
    return text;
  };

  closeConfirm = () => {
    this.props.appStore?.closeModal();
    this.setState({ confirmModalVisible: false });
  };

  openImageViewer = (data: string[], imageIndex: number) => {
    this.props?.appStore?.openModal(true);
    this.setState({ imageModalVisible: true, images: data, currentImage: imageIndex });
  };

  openVideoViewer = (url: string) => {
    this.props?.appStore?.openModal(true);
    this.setState({ videoModalVisible: true, videoUrl: url });
  };

  nextImage = () => {
    this.setState({
      currentImage: this.state.currentImage === this.state.images.length - 1 ? 0 : ++this.state.currentImage,
    });
  };
  prevImage = () => {
    this.setState({
      currentImage: this.state.currentImage === 0 ? this.state.images.length - 1 : --this.state.currentImage,
    });
  };

  render() {
    const { feed } = this.props;
    const {
      hiddenEditFeedModal,
      sending,
      loading,
      messages,
      currentPage,
      count,
      offset,
      confirmModalVisible,
      imageModalVisible,
      videoModalVisible,
    } = this.state;
    return (
      <Box className={style.nonScroll} style={{ position: "relative" }}>
        <SpinContainer loading={sending}>
          {!sending && !loading && feed === undefined && (
            <Card my={5} pt={4}>
              {/* <Text size={400}>Congrats</Text> */}
              <NoData type="congrats" description="Feeds was deleted. Choose or create other feed" />
            </Card>
          )}
          {feed !== undefined && (
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Flex alignItems={"center"}>
                <Text fontSize={"25px"} lineHeight={"28px"} fontWeight={500} mb={1}>
                  {feed?.name}
                </Text>
                <Button mt={1} ml={2} mr={1} size="m" theme="muted" use="tertiary">
                  <Button.Addon onClick={() => this.changeHiddenEditFeedModal(false)}>
                    <EditS color={"#A6B0B3"} />
                  </Button.Addon>
                </Button>
                <Button
                  mt={1}
                  onClick={() => {
                    this.props.appStore?.openModal();
                    this.setState({ confirmModalVisible: true });
                  }}
                  size="m"
                  theme="muted"
                  use="tertiary">
                  <Button.Addon>
                    <TrashS color={"#A6B0B3"} />
                  </Button.Addon>
                </Button>
              </Flex>
              <Box mb={1}>
                <DateRangePicker onChange={this.changeRange} />
              </Box>
            </Flex>
          )}
          {feed !== undefined && !loading && messages.length === 0 && (
            <Card my={5} pt={4}>
              {/* <Text size={400}>Congrats</Text> */}
              <NoData type="other-data" description="No messages" />
            </Card>
          )}
          {feed !== undefined && (
            <StackGrid columnWidth={444} style={{ marginTop: 16 }} gutterWidth={16} gutterHeight={16}>
              {loading &&
                Array(4)
                  .fill("")
                  .map(() => <FeedCardSkeleton />)}
              {!loading &&
                messages
                  .slice(currentPage === 1 ? 0 : (currentPage - 1) * 100, currentPage * 100)
                  .map((message: MessageType, index) => {
                    let images: string[] = [];
                    let videos: VideoType[] = [];
                    if (message.files.length > 0) {
                      message.files.forEach((anyUrl) => {
                        if (/_tgparser_thumbnail.jpg/.test(anyUrl.file)) {
                          videos.push({
                            preview: anyUrl.file,
                            url: anyUrl.file.replace("_tgparser_thumbnail.jpg", ""),
                          });
                        } else {
                          if (/\.(gif|jpg|jpeg|tiff|png)$/i.test(anyUrl.file)) {
                            images.push(anyUrl.file);
                          }
                        }
                        // if (/\.(gif|jpg|jpeg|tiff|png)$/i.test(anyUrl.file)) {
                        //   images.push(anyUrl.file);
                        // } else {
                        //   videos.push(anyUrl.file);
                        // }
                      });
                    }
                    return (
                      <FeedCard
                        messageLink={`${startUrl}${message?.source.link}/${message.internal_id}`}
                        key={message.id + "message" + index}
                        title={message?.source.name ?? "Not title"}
                        description={message?.source?.description}
                        message={this.findAndSelectionKeyword(message)}
                        link={`${startUrl}${message?.source.link}`}
                        date={moment(message?.date).format("ll HH:MM")}
                        favorite={message.favorites}
                        onClickAddToFav={() => this.addMessageToFavorite(message.id)}
                        onClickRemoveFromFav={() => this.deleteMessageFromFavorite(message.id)}
                        images={images}
                        videos={videos}
                        onClickImage={this.openImageViewer}
                        onClickVideo={this.openVideoViewer}
                      />
                    );
                  })}
            </StackGrid>
          )}
          {!loading && messages.length > 0 && (
            <Pagination
              onCurrentPageChange={this.onCurrentPageChange}
              currentPage={offset + 1}
              totalPages={Math.ceil(count / 50)}
              mt={5}
            />
          )}
          <EditFeedModal
            feedId={feed ? feed.id : null}
            toolName={"Telegram Tracker"}
            hidden={hiddenEditFeedModal}
            changeHiddenModalCallback={() => this.changeHiddenEditFeedModal(true)}
            editFeedCallback={this.editFeedCallback}
          />

          <Modal
            w={"90vw"}
            h={"90vh"}
            visible={videoModalVisible}
            onClose={() => {
              this.props?.appStore?.closeModal();
              this.setState({ videoModalVisible: false, videoUrl: "" });
            }}>
            <Modal.Overlay style={{ background: "transparent" }}>
              <Modal.Window w={"90vw"} h={"90vh"} style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)" }}>
                {/* {this.state.videoUrl !== "" && ( */}
                <ReactPlayer controls={true} url={this.state.videoUrl} width={"100%"} height={"100%"} />
                {/* )} */}
              </Modal.Window>
            </Modal.Overlay>
          </Modal>

          <Modal
            visible={imageModalVisible}
            onClose={() => {
              this.props?.appStore?.closeModal();
              this.setState({ imageModalVisible: false, images: [], currentImage: 0 });
            }}>
            <Modal.Overlay style={{ background: "transparent" }}>
              <Modal.Window w={"90vw"} h={"90vh"} style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)" }}>
                {imageModalVisible && this.state.images.length > 0 && this.state.currentImage !== null && (
                  <div
                    style={{
                      backgroundImage: `url(${
                        this.state.images[this.state.currentImage !== null ? this.state.currentImage : 0]
                      })`,
                      width: "100%",
                      height: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                  />
                )}
                <div
                  onClick={this.prevImage}
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    left: 40,
                    top: "50%",
                    backgroundColor: "#ccc",
                  }}>
                  <ArrowLeftM />
                </div>
                <div
                  onClick={this.nextImage}
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    right: 40,
                    top: "50%",
                    backgroundColor: "#ccc",
                  }}>
                  <ArrowRightM />
                </div>
              </Modal.Window>
            </Modal.Overlay>
          </Modal>
          <Modal visible={confirmModalVisible} onClose={this.closeConfirm}>
            <Modal.Overlay style={{ background: "transparent" }}>
              <Modal.Window style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)" }}>
                <Text tag="h2" size={400} mb={4}>
                  Confirmation to delete feed:
                </Text>
                <Text size={200} mb={3} tag="p">
                  Are you sure you want to delete this feed?
                </Text>
                <Button
                  use="primary"
                  theme="danger"
                  onClick={() => {
                    this.deleteFeed();
                    this.closeConfirm();
                  }}>
                  Delete
                </Button>
                <Button ml={2} onClick={this.closeConfirm}>
                  Cancel
                </Button>
              </Modal.Window>
            </Modal.Overlay>
          </Modal>
          <SpinContainer.Overlay style={{ alignItems: "flex-start" }}>
            <Spin tag={Sticky} top="200px" size="xxl" />
          </SpinContainer.Overlay>
        </SpinContainer>
      </Box>
    );
  }
}

export default FeedTab;
