import React, { FC } from "react";
import { Flex, Box } from "@semcore/ui/flex-box";
import { Text } from "@semcore/typography";
import style from "./style.module.scss";
import Card from "@semcore/ui/card";
import Button from "@semcore/ui/button";
import Link from "@semcore/ui/link";
import FavoriteS from "@semcore/icon/lib/Favorite/s";
import CopyXS from "@semcore/icon/lib/Copy/xs";
import LinkExternalXS from "@semcore/icon/lib/LinkExternal/xs";
import Tooltip from "@semcore/tooltip";
import playIcon from "../../assets/images/play.png";
import VideoAltM from "@semcore/icon/lib/VideoAlt/m";
import { VideoType } from "../../utils/types";
interface IFeedCard {
  title: string;
  description?: string;
  message: string;
  link: string;
  date: string;
  onClickAddToFav: () => void;
  onClickRemoveFromFav: () => void;
  favorite: boolean;
  messageLink: string;
  images: string[];
  videos: VideoType[];
  onClickImage: (data: string[], index: number) => void;
  onClickVideo: (url: string) => void;
}

export const FeedCard: FC<IFeedCard> = ({
  title,
  description,
  message,
  link,
  date,
  onClickAddToFav,
  favorite,
  onClickRemoveFromFav,
  messageLink,
  images,
  videos,
  onClickImage,
  onClickVideo,
}) => {
  return (
    <Card p={6} pt={5} pb={5} pr={5}>
      <Flex justifyContent={"space-between"} alignItems={"center"} pb={2}>
        <Flex alignItems={"center"}>
          <div
            style={{
              backgroundImage: `url(${require("../../assets/images/telegramIcon.png")})`,
              minWidth: 20,
              minHeight: 20,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              marginRight: 4,
            }}
          />
          <Text mr={2}>{title}</Text>
          {description && (
            <>
              <Text>{"/"}</Text>
              <Tooltip
                interaction="hover"
                placement="top"
                theme="default"
                title={<div dangerouslySetInnerHTML={{ __html: description }} />}>
                <Text ml={2} className={style.description}>
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </Text>
              </Tooltip>
            </>
          )}
        </Flex>
        <Tooltip
          interaction="hover"
          placement="top"
          theme="default"
          title={favorite ? "Remove from Favourites" : "Add to Favorites"}>
          <Button onClick={favorite ? onClickRemoveFromFav : onClickAddToFav} size="m" theme="muted" use="tertiary">
            <Button.Addon>
              <FavoriteS color={favorite ? "#FF5E00" : "#A6B0B3"} />
            </Button.Addon>
          </Button>
        </Tooltip>
      </Flex>
      <Text color={"#757575"} fontSize={"14px"} fontWeight={400} style={{ overflowWrap: "break-word" }}>
        <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: message }} />
        {/* <div style={{ display: "inline" }}>{message}</div> */}

        {/* <Button onClick={() => window.open(messageLink)} size="m" theme="muted" use="tertiary" style={{ display: 'inline' }} > */}
        <LinkExternalXS
          onClick={() => window.open(messageLink)}
          color={"#A6B0B3"}
          style={{ display: "inline", marginTop: 6, marginLeft: 4 }}
        />
        {/* </Button> */}
      </Text>
      <Flex justifyContent={"flex-start"}>
        {(images.length > 0 || videos.length > 0) && (
          <Flex
            style={{
              marginTop: 12,
              marginBottom: -12,
              backgroundColor: "#EDEFF0",
              padding: 10,
              paddingLeft: 30,
              borderRadius: 2,
              position: "relative",
              gap: 8,
            }}
            flexWrap={true}>
            <div
              style={{
                backgroundImage: `url(${require("../../assets/images/shape.png")})`,
                width: 11,
                height: 12,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                position: "absolute",
                top: 10,
                left: 10,
              }}
            />
            {images.map((imageUrl, index) => (
              <div
                onClick={() => onClickImage(images, index)}
                key={`image_${index}`}
                style={{
                  width: 72,
                  height: 72,
                  backgroundImage: `url(${imageUrl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  //   marginRight: 8,
                  cursor: "pointer",
                  borderRadius: 2,
                }}
              />
            ))}
            {videos.map((video, index) => (
              <div
                onClick={() => onClickVideo(video.url)}
                key={`video_${index}`}
                style={{
                  width: 72,
                  height: 72,
                  border: "1px solid #ccc",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  borderRadius: 2,
                  cursor: "pointer",
                  backgroundImage: `url(${video.preview})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}>
                <VideoAltM color={"#fff"} style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)", borderRadius: 11 }} />
                {/* <div
                  style={{
                    // backgroundImage: `url(${playIcon})`,
                    backgroundImage: `url(${require("../../assets/images/shape.png")})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    width: 37,
                    height: 37,
                  }}
                /> */}
              </div>
            ))}
          </Flex>
        )}
      </Flex>
      <Flex alignItems={"center"} mt={6}>
        <Link onClick={() => window.open(link)}>
          <Link.Text>{link}</Link.Text>
        </Link>
        <Tooltip interaction="hover" placement="top" theme="default" title={"Copy"}>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
            size="m"
            theme="muted"
            use="tertiary"
            ml={1}>
            <Button.Addon>
              <CopyXS color={"#A6B0B3"} />
            </Button.Addon>
          </Button>
        </Tooltip>
      </Flex>
      <Flex justifyContent={"flex-end"} mt={2}>
        <Text fontSize={"12px"} fontWeight={400} lineHeight={"20px"} color={"#828282"}>
          {date}
        </Text>
      </Flex>
    </Card>
  );
};
