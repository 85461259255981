import React, { Component } from "react";
import FullscreenModal from "@semcore/fullscreen-modal";
import Button from "@semcore/button";
import { Flex, Box } from "@semcore/ui/flex-box";
import Input from "@semcore/input";
import Link from "@semcore/ui/link";
import style from "./style.module.scss";
import { Text } from "@semcore/typography";
import Notice from "@semcore/notice";
import { addSource, checkChannel, editFeed, getFeed } from "../../api/request";
import { inject, observer } from "mobx-react";
import { appStore } from "../../stores";
import { TableLoader } from "../../components";
import Tooltip from "@semcore/tooltip";
import { FeedType, SourceType } from "../../utils/types";
import Select from "@semcore/select";

interface IModal {
  hidden: boolean;
  changeHiddenModalCallback: (hidden: boolean) => void;
  toolName: string;
  appStore?: typeof appStore;
}

type State = {
  sending: boolean;
  choosedFeed?: FeedType;
  selectEmpty: boolean;
};

@inject("appStore")
@observer
class AddChannelToFeed extends Component<IModal, State> {
  state: State = {
    sending: false,
    choosedFeed: undefined,
    selectEmpty: false,
  };

  componentDidUpdate(prevProps: IModal) {
    if (prevProps.hidden === true && this.props.hidden === false) {
      this.props.appStore?.openModal();
    }
  }

  saveFeed = () => {
    const { choosedFeed } = this.state;

    if (choosedFeed) {
      this.setState({ sending: true }, () => {
        getFeed(choosedFeed?.id)
          .then((json: FeedType) => {
            // console.log("getFeedSuccess", json);
            let feed: FeedType = json;
            let newSource = this.props.appStore?.sources.find(
              (source: SourceType) => source.id === this.props.appStore?.sourceId
            );
            if (newSource) {
              feed.sources.push({
                link: newSource.link,
                type: "telegram",
                id: newSource.id,
              });
            }
            editFeed(feed).finally(() => {
              this.props.appStore?.getAllFeeds().finally(() => {
                this.setState({ sending: false });
                this.props.changeHiddenModalCallback(true);
              });
            });
          })
          .catch((error) => {
            // console.log("getFeedError", error);
          });
      });
    } else {
      this.setState({ selectEmpty: true });
    }
  };

  chooseFeed = (id: number) => {
    // console.log(id);
    if (this.state.selectEmpty) {
      this.setState({ selectEmpty: false });
    }
    this.setState({ choosedFeed: this.props.appStore?.feeds.find((feed: FeedType) => feed.id === id) });
  };

  render() {
    const { toolName, hidden, changeHiddenModalCallback } = this.props;
    const { sending, choosedFeed, selectEmpty } = this.state;
    return (
      <FullscreenModal
        visible={!hidden}
        onClose={() => {
          changeHiddenModalCallback(true);
          this.props.appStore?.closeModal();
        }}>
        {sending && <TableLoader />}
        <FullscreenModal.Close />
        <FullscreenModal.Back>{`Go back`}</FullscreenModal.Back>
        <FullscreenModal.Header title="Add channel to existing feed" />
        <FullscreenModal.Body>
          <Flex justifyContent={"center"} flex={1}>
            <Box w={"403px"} pt={8}>
              <Text color={"#333333"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"}>
                {"Feed Name"}
              </Text>
              <Flex mt={3} alignItems={"center"}>
                <Box mt={2} wMin={"403px"}>
                  <Tooltip w={"403px"} theme="warning">
                    <Tooltip.Trigger w={"403px"}>
                      <Select
                        onChange={this.chooseFeed}
                        placeholder="Choose feed"
                        size="l"
                        state={selectEmpty ? "invalid" : "normal"}>
                        <Select.Trigger mr={2} wMin={403} wMax={403}>
                          {choosedFeed !== undefined && choosedFeed?.name}
                        </Select.Trigger>
                        <Select.Menu hMax={500}>
                          {this.props.appStore?.feeds.map((feed: FeedType, index) => (
                            <Select.Option key={feed.id + "feed" + index} value={feed.id}>
                              <Select.OptionTitle>{feed.name}</Select.OptionTitle>
                            </Select.Option>
                          ))}
                        </Select.Menu>
                      </Select>
                    </Tooltip.Trigger>
                    {selectEmpty && <Tooltip.Popper>{`You must choose feed`}</Tooltip.Popper>}
                  </Tooltip>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </FullscreenModal.Body>
        <FullscreenModal.Footer>
          <Flex justifyContent={"center"} alignItems={"center"} flex={1} h={"56px"}>
            <Button size="l" onClick={this.saveFeed} theme={undefined} use="primary" mr={3}>
              Save Feed
            </Button>
            <Button
              onClick={() => {
                changeHiddenModalCallback(true);
                this.props.appStore?.closeModal();
              }}
              size="l"
              theme={undefined}
              use="secondary">
              Cancel
            </Button>
          </Flex>
        </FullscreenModal.Footer>
      </FullscreenModal>
    );
  }
}
export default AddChannelToFeed;
