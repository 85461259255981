import React, { Component } from "react";
import Page from "../../ui/Page";
import { Flex, Box } from "@semcore/ui/flex-box";
import { Text } from "@semcore/typography";
import resolveColor from "@semcore/utils/lib/color";
import Card from "@semcore/ui/card";
import Button from "@semcore/ui/button";
import MathPlusXS from "@semcore/icon/lib/MathPlus/xs";
import Table from "@semcore/ui/table";
import Link from "@semcore/ui/link";
import Badge from "@semcore/ui/badge";
import EditS from "@semcore/icon/lib/Edit/s";
import TrashS from "@semcore/icon/lib/Trash/s";
import { ActionCardType, Sort } from "./startPageTypes";
import {
  AddChannelToFeed,
  AddSourceToFeedModal,
  CreateFeedModal,
  CreateSourceModal,
  EditFeedModal,
  EditSourceModal,
} from "../../modals";
import { inject, observer } from "mobx-react";
import { appStore } from "../../stores";
import Skeleton from "@semcore/skeleton";
import { TabsKeys } from "../main/mainPageTypes";
import { deleteFeed } from "../../api/request";
import { TableLoader } from "../../components";
import { FeedType, SourceType } from "../../utils/types";
import Dropdown from "@semcore/dropdown";
import SpinContainer from "@semcore/spin-container";
import Sticky from "@semcore/sticky";
import Spin from "@semcore/spin";
import Modal from "@semcore/modal";
import Scroll from "@semcore/scroll-area";
import style from "./style.module.scss";
import Select from "@semcore/select";
import { LinkTrigger } from "@semcore/base-trigger";
import NoticeBubbleManager from "@semcore/notice-bubble";
import { NoticeBubbleContainer, NoticeBubble } from "@semcore/notice-bubble";
import { NoData } from "@semcore/widget-empty";

const TITLE = "Telegram Tracker";
const SUBTITLE =
  "Track messages in thousands Telegram channels or groups by keywords. Organize your channels and groups messages into dashboards.";

const ActionCard = ({ tooltipContent, title, description, onClickAdd, onClickSee }: ActionCardType) => {
  return (
    <Card pt={5} pb={5} w="400px" wMax="400px">
      <Flex mb={2} alignItems="center">
        <Card.Title hint={tooltipContent}>{title}</Card.Title>
      </Flex>
      <Card.Description mb={5} h={"34px"}>
        {description}
      </Card.Description>
      <Flex>
        <Button onClick={onClickAdd} size="l" theme="info" use="primary">
          <Button.Addon>
            <MathPlusXS />
          </Button.Addon>
          <Button.Text>{title === "Channel" ? "Add Channel" : "Add Feed"}</Button.Text>
        </Button>
        {onClickSee && (
          <Button onClick={onClickSee} ml={3} size="l" theme="muted" use="secondary">
            {"See My Channels"}
          </Button>
        )}
      </Flex>
    </Card>
  );
};

interface IPage {
  history: any;
  appStore: typeof appStore;
}

type State = {
  hidden: boolean;
  hiddenAddFeedModal: boolean;
  loading: boolean;
  sending: boolean;
  hiddenEditFeedModal: boolean;
  choosedFeed: number | null;
  hiddenAddChannelToFeed: boolean;
  editSource?: SourceType;
  editSourceModalHidden: boolean;
  confirmModalVisible: boolean;
  sort: Sort;
};

@inject("appStore")
@observer
class StartPage extends Component<IPage, State> {
  state = {
    hiddenAddFeedModal: true,
    hiddenEditFeedModal: true,
    hidden: true,
    loading: false,
    sending: false,
    choosedFeed: null,
    hiddenAddChannelToFeed: true,
    editSource: undefined,
    editSourceModalHidden: true,
    confirmModalVisible: false,
    sort: Sort.asc,
  };

  componentDidMount() {
    const { getAllFeeds, getAllSource, getSourceGroup, getSourceInfo } = this.props.appStore;
    getAllFeeds();
    getAllSource();
    getSourceGroup();
    getSourceInfo();
  }

  deleteFeedId?: number;

  addSource = () => {
    // console.log("addSource");
    this.changeHiddenModal(false);
  };

  getSourceCell = (sources: SourceType[]) => {
    if (sources.length === 0) {
      return <span>no sources</span>;
    } else if (sources.length === 1) {
      return <Link onClick={() => this.editSource(sources[0])}>{sources[0].link}</Link>;
    } else if (sources.length === 2) {
      return sources.map((source: SourceType, index) => (
        <Link onClick={() => this.editSource(source)} ml={index == 1 ? "2px" : "0px"} key={"source" + source.id}>
          {`${source.link}${index === 0 ? "," : ""}`}
        </Link>
      ));
    } else if (sources.length > 2) {
      return (
        <Flex alignItems={"center"}>
          {/* <Box> */}
          {/* <Flex> */}
          {sources.map((source: SourceType, index) => {
            if (index < 2) {
              return (
                <Link
                  onClick={() => this.editSource(source)}
                  ml={index == 1 ? "2px" : "0px"}
                  key={"source" + source.id}>
                  {`${source.link}${index === 0 ? "," : ""}`}
                </Link>
              );
            } else {
              return null;
            }
          })}
          {/* </Flex> */}
          {/* </Box> */}
          {/* <Box> */}
          <Select
            tag={LinkTrigger}
            color={"#757575"}
            onChange={(value) => {
              // console.log(value);
              let findedSource = sources.find((source: SourceType) => source.id === value);
              if (findedSource) {
                this.editSource(findedSource);
              }
            }}
            ml={2}
            mt={1}
            options={sources.slice(2).map((source: SourceType, index) => {
              return { value: source.id, label: source.name, children: source.link };
            })}
            placeholder={`${sources.length - 2} more`}
            m="auto"
            value={null}
          />
          {/* </Box> */}
          {/* <Dropdown interaction="click" placement="top" stretch="min">
            <Dropdown.Trigger>
              <LinkTrigger color={"#757575"} ml={2} style={{ borderBottom: "1px dotted" }}>
                <LinkTrigger.Text onClick={() => {}}>{`${sources.length - 2} more`}</LinkTrigger.Text>
              </LinkTrigger>
            </Dropdown.Trigger>
            <Dropdown.Popper p={4}>
              <Scroll hMax={200}>
                {sources.map((source: SourceType, index) => {
                  if (index > 1) {
                    return (
                      <>
                        <Link onClick={() => this.editSource(source)} mb={1} key={"source" + source.id}>
                          {`${source.link}${index === 0 ? "," : ""}`}
                        </Link>
                        <br />
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
              </Scroll>
            </Dropdown.Popper>
          </Dropdown> */}
        </Flex>
      );
    }
  };

  convertStringToArray = (words: string) => {
    let wordsArray = words.split("\r\n");
    return wordsArray.map((word) => word.replaceAll("/", ""));
  };

  getKeywordCell = (words: string, feedId: number) => {
    let wordsArray = this.convertStringToArray(words);
    // console.log("wordsArray", wordsArray);
    if (wordsArray.length === 0 || wordsArray[0] === "") {
      return <span>no keywords</span>;
    } else if (wordsArray.length === 1) {
      return <Text>{wordsArray[0]}</Text>;
    } else if (wordsArray.length === 2) {
      return wordsArray.map((source, index) => (
        <Text ml={index == 1 ? "2px" : "0px"}>{`${source}${index === 0 ? "," : ""}`}</Text>
      ));
    } else if (wordsArray.length > 2) {
      return (
        <Flex>
          {wordsArray.map((source, index) => {
            if (index < 2) {
              return <Text ml={index == 1 ? "2px" : "0px"}>{`${source}${index === 0 ? "," : ""}`}</Text>;
            } else {
              return null;
            }
          })}

          <Select
            tag={LinkTrigger}
            color={"#757575"}
            onChange={(value) => {}}
            ml={2}
            options={wordsArray.slice(2).map((word: string, index) => {
              return { value: word, label: word, children: word };
            })}
            placeholder={`${wordsArray.length - 2} more`}
            m="auto"
            mt={1}
            value={null}
          />

          {/* <Dropdown interaction="click" placement="top" stretch="min">
            <Dropdown.Trigger>
              <LinkTrigger color={"#757575"} ml={2} style={{ borderBottom: "1px dotted" }}>
                <LinkTrigger.Text onClick={() => {}}>{`${wordsArray.length - 2} more`}</LinkTrigger.Text>
              </LinkTrigger>
            </Dropdown.Trigger>
            <Dropdown.Popper p={4}>
              <Scroll hMax={200}>
                {wordsArray.map((word: string, index) => {
                  if (index > 1) {
                    return (
                      <>
                        <Link
                          onClick={() => this.changeHiddenEditFeedModal(false, feedId)}
                          mb={1}
                          key={"source" + word}>
                          {`${word}${index === 0 ? "," : ""}`}
                        </Link>
                        <br />
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
              </Scroll>
            </Dropdown.Popper>
          </Dropdown> */}
        </Flex>
      );
    }
  };

  toFeed = (id: number) => {
    this.props.history.push(`/Main/${TabsKeys.feeds}/${id}`);
  };

  toSources = () => {
    this.props.history.push(`/Main/${TabsKeys.sources}/null`);
  };

  editFeedCallback = () => {
    const { getAllFeeds } = this.props.appStore;
    this.setState({ sending: true }, () => {
      getAllFeeds().finally(() => {
        this.setState({ sending: false });
      });
    });
  };

  deleteFeed = () => {
    this.setState({ sending: true }, () => {
      if (this.deleteFeedId) {
        this.setState({ confirmModalVisible: false });
        deleteFeed(this.deleteFeedId)
          .then((json) => {
            // console.log("deleteFeedSuccess", json);
            this.props.appStore.getAllFeeds().finally(() => {
              this.setState({ sending: false });
            });
            this.deleteFeedId = undefined;
          })
          .catch((error) => {
            // console.log("deleteFeedError", error);
            this.setState({ sending: false });
            this.deleteFeedId = undefined;
          });
      } else {
        this.setState({ sending: false });
      }
    });
  };

  openAddFeedModal = () => {};

  changeHiddenModal = (hidden: boolean) => {
    this.setState({ hidden });
  };

  changeHiddenAddFeedModal = (hidden: boolean) => {
    this.setState({ hiddenAddFeedModal: hidden });
  };

  changeHiddenEditFeedModal = (hidden: boolean, feedId: number | null) => {
    this.setState({ hiddenEditFeedModal: hidden, choosedFeed: feedId });
  };

  renderRows = (feed: FeedType) => {
    return (
      <Table.Row key={"feed" + feed.id}>
        <Table.Cell valign={"center"} pt={"8px"} pb={"8px"}>
          <Link>
            <Link.Text onClick={() => this.toFeed(feed.id)}>{feed.name}</Link.Text>
            {feed.new_messages > 0 && (
              <Link.Addon ml={2}>
                <Badge bg="orange" color="white">
                  {feed.new_messages}
                </Badge>
              </Link.Addon>
            )}
          </Link>
        </Table.Cell>
        <Table.Cell valign={"center"} pt={"8px"} pb={"8px"}>
          {this.getSourceCell(feed.sources)}
        </Table.Cell>
        <Table.Cell w={"403px"} valign={"center"} pt={"8px"} pb={"8px"}>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Box>{this.getKeywordCell(feed.words, feed.id)}</Box>
            <Box>
              <Flex>
                <Button
                  onClick={() => {
                    this.changeHiddenEditFeedModal(false, feed.id);
                  }}
                  mr={2}
                  size="m"
                  theme="muted"
                  use="tertiary">
                  <Button.Addon>
                    <EditS color={"#A6B0B3"} />
                  </Button.Addon>
                </Button>
                <Button
                  onClick={() => {
                    this.deleteFeedId = feed.id;
                    this.setState({ confirmModalVisible: true });
                    this.props.appStore.openModal();
                  }}
                  size="m"
                  theme="muted"
                  use="tertiary">
                  <Button.Addon>
                    <TrashS color={"#A6B0B3"} />
                  </Button.Addon>
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Table.Cell>
      </Table.Row>
    );
  };

  editSource = (source: SourceType) => {
    this.setState({ editSource: source }, () => {
      this.changeHiddenEditSourceModal(false);
    });
  };

  changeHiddenEditSourceModal = (hidden: boolean) => {
    if (hidden) {
      this.setState({ editSource: undefined, editSourceModalHidden: hidden });
    } else {
      this.setState({ editSourceModalHidden: hidden });
    }
  };

  addChannelToFeedCallback = (id: number) => {
    this.props.appStore?.setSourceId(id);
    this.changeHiddenModal(true);
    this.changeHiddenAddChannelToFeed(false);
  };

  changeHiddenAddChannelToFeed = (hidden: boolean) => {
    if (hidden) {
      this.props.appStore?.setSourceId();
    }
    this.setState({ hiddenAddChannelToFeed: hidden });
  };

  createFeedWithChannelCallback = (id: number) => {
    this.props.appStore?.setSourceId(id);
    this.changeHiddenModal(true);
    this.changeHiddenCreateFeedModal(false);
  };

  changeHiddenCreateFeedModal = (hidden: boolean) => {
    if (hidden) {
      this.props.appStore?.setSourceId();
    }
    this.setState({ hiddenAddFeedModal: hidden });
  };

  closeConfirm = () => {
    this.deleteFeedId = undefined;
    this.setState({ confirmModalVisible: false });
    this.props.appStore.closeModal();
  };

  changeSort = () => {
    const { sort } = this.state;
    this.setState({ sort: sort === Sort.asc ? Sort.desc : Sort.asc });
  };

  sortArray = (data: FeedType[]) => {
    let sortArrayRender = JSON.parse(JSON.stringify(data));
    sortArrayRender.sort((a: FeedType, b: FeedType) => {
      if (this.state.sort === Sort.desc) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      } else if (this.state.sort === Sort.asc) {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    });
    return sortArrayRender;
  };

  render() {
    const {
      sort,
      confirmModalVisible,
      hidden,
      sending,
      hiddenAddFeedModal,
      hiddenEditFeedModal,
      choosedFeed,
      hiddenAddChannelToFeed,
      editSource,
      editSourceModalHidden,
    } = this.state;
    const { feeds, feedsLoading } = this.props.appStore;
    return (
      <Page>
        {/* <NoticeBubbleContainer />
        <NoticeBubble visible={true} duration={3000}>
          Текст
        </NoticeBubble> */}
        <div id={"heightControl"} className={style.nonScroll}>
          <Flex justifyContent={"center"}>
            <Box mt={10}>
              <Text color={resolveColor("gray20")} fontSize={"48px"} lineHeight={"56px"} fontWeight={700}>
                {TITLE}
              </Text>
            </Box>
          </Flex>
          <Flex justifyContent={"center"}>
            <Box mt={5}>
              <Text color={resolveColor("gray20")} fontSize={"16px"} lineHeight={"24px"} fontWeight={400}>
                {SUBTITLE}
              </Text>
            </Box>
          </Flex>
          <Box mt={"18px"}>
            <Flex justifyContent={"center"}>
              <ActionCard
                title={"Channel"}
                onClickAdd={this.addSource}
                onClickSee={this.toSources}
                description={"Add channels or groups you want to track"}
                tooltipContent={
                  "Channel - Add Telegram channels or groups (public or private) to track posts.  Use Channels to create a Feed to see posts filtered by keywords"
                }
              />
              <Box w={16} />
              <ActionCard
                title={"Feed"}
                onClickAdd={() => this.changeHiddenAddFeedModal(false)}
                description={"Create feed from several channels or groups, with or without keyword filter"}
                tooltipContent={
                  "Feed - Select channels and define keywords to track and organize a feed. Feed is a dashboard where you can see all tracked posts from selected channels."
                }
              />
            </Flex>
          </Box>
          <Box mt={7} mb={5}>
            <Text fontSize={"25px"} lineHeight={"28px"} fontWeight={500}>
              {"My Feeds"}
            </Text>
          </Box>
          <Box position={"relative"}>
            <SpinContainer loading={sending}>
              {/* {sending && <TableLoader />} */}
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.CellHead
                      onClick={this.changeSort}
                      active={true}
                      sorting={sort}
                      valign="middle"
                      w={"388px"}
                      wMax={"388px"}>
                      <span>{"Name"}</span>
                    </Table.CellHead>
                    <Table.CellHead align={"left"}>
                      <span>{"Sources"}</span>
                    </Table.CellHead>
                    <Table.CellHead align={"left"}>
                      <span>{"Keywords"}</span>
                    </Table.CellHead>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {!feedsLoading && this.sortArray(feeds).map(this.renderRows)}
                  {feedsLoading &&
                    Array(5)
                      .fill("")
                      .map(() => (
                        <Table.Row>
                          <Table.Cell valign={"center"} pt={"8px"} pb={"8px"}>
                            <Skeleton height={"15px"}>
                              <Skeleton.Text y="6" width="80%" />
                            </Skeleton>
                          </Table.Cell>
                          <Table.Cell valign={"center"} pt={"8px"} pb={"8px"}>
                            <Skeleton height={"15px"}>
                              <Skeleton.Text y="6" width="50%" />
                            </Skeleton>
                          </Table.Cell>
                          <Table.Cell w={"403px"} valign={"center"} pt={"8px"} pb={"8px"}>
                            <Skeleton height={"15px"}>
                              <Skeleton.Text y="6" width="50%" />
                            </Skeleton>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                </Table.Body>
              </Table>
              {!feedsLoading && feeds.length == 0 && (
                <Card my={5} pt={4}>
                  <NoData type="other-data" description="Feeds is empty" />
                </Card>
              )}
              <SpinContainer.Overlay style={{ alignItems: "flex-start" }}>
                <Spin tag={Sticky} top="200px" size="xxl" />
              </SpinContainer.Overlay>
            </SpinContainer>
          </Box>
          <EditSourceModal
            toolName={"Channels"}
            editSource={editSource}
            hidden={editSourceModalHidden}
            changeHiddenModalCallback={this.changeHiddenEditSourceModal}
            addChannelToFeedCallback={this.addChannelToFeedCallback}
            createFeedWithChannelCallback={this.createFeedWithChannelCallback}
          />
          <CreateSourceModal
            toolName={"Channels"}
            hidden={hidden}
            changeHiddenModalCallback={this.changeHiddenModal}
            addChannelToFeedCallback={this.addChannelToFeedCallback}
            createFeedWithChannelCallback={this.createFeedWithChannelCallback}
          />
          <AddChannelToFeed
            toolName={"Channels"}
            hidden={hiddenAddChannelToFeed}
            changeHiddenModalCallback={this.changeHiddenAddChannelToFeed}
          />
          <CreateFeedModal
            toolName={"Telegram Tracker"}
            hidden={hiddenAddFeedModal}
            changeHiddenModalCallback={() => this.changeHiddenAddFeedModal(true)}
          />
          <EditFeedModal
            editFeedCallback={this.editFeedCallback}
            feedId={choosedFeed}
            toolName={"Telegram Tracker"}
            hidden={hiddenEditFeedModal}
            changeHiddenModalCallback={() => this.changeHiddenEditFeedModal(true, null)}
          />
          <Modal visible={confirmModalVisible} onClose={this.closeConfirm}>
            <Modal.Overlay style={{ background: "transparent" }}>
              <Modal.Window style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)" }}>
                <Text tag="h2" size={400} mb={4}>
                  Confirmation to delete feed:
                </Text>
                <Text size={200} mb={3} tag="p">
                  Are you sure you want to delete this feed?
                </Text>
                <Button
                  use="primary"
                  theme="danger"
                  onClick={() => {
                    this.deleteFeed();
                  }}>
                  Delete
                </Button>
                <Button ml={2} onClick={this.closeConfirm}>
                  Cancel
                </Button>
              </Modal.Window>
            </Modal.Overlay>
          </Modal>
        </div>
      </Page>
    );
  }
}

export default StartPage;
