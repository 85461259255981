import React, { Component } from "react";
import FullscreenModal from "@semcore/fullscreen-modal";
import Button from "@semcore/button";
import { Flex, Box } from "@semcore/ui/flex-box";
import { Text } from "@semcore/typography";
import Input from "@semcore/input";
import Tooltip from "@semcore/tooltip";
import InfoOutlineS from "@semcore/icon/lib/InfoOutline/s";
import Select from "@semcore/select";
import Link from "@semcore/ui/link";
import MathPlusXS from "@semcore/icon/lib/MathPlus/xs";
import Tag from "@semcore/tag";
import Checkbox from "@semcore/checkbox";
import { Equality, SourceGroupsType, SourceType, KeywordOptionType, KeywordType } from "../../utils/types";
import { inject, observer } from "mobx-react";
import { appStore } from "../../stores";
import { addFeed } from "../../api/request";
import { TableLoader } from "../../components";
import Spin from "@semcore/spin";
import Scroll from "@semcore/scroll-area";

const StrictEqualIcon = ({ style }: { style?: React.CSSProperties }) => (
  <div
    style={{
      backgroundImage: `url(${require("../../assets/images/strictEqual.png")})`,
      width: 12,
      height: 12,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      ...style,
    }}
  />
);
const EqualIcon = ({ style }: { style?: React.CSSProperties }) => (
  <div
    style={{
      backgroundImage: `url(${require("../../assets/images/equal.png")})`,
      width: 12,
      height: 12,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      ...style,
    }}
  />
);
const NotEqualIcon = ({ style }: { style?: React.CSSProperties }) => (
  <div
    style={{
      backgroundImage: `url(${require("../../assets/images/notEqual.png")})`,
      width: 12,
      height: 12,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      ...style,
    }}
  />
);

const keywordsOptions: KeywordOptionType[] = [
  { title: "strict equal", value: 0, type: Equality.strictEqual },
  { title: "equal", value: 1, type: Equality.equal },
  { title: "not equal", value: 2, type: Equality.notEqual },
];

interface IModal {
  hidden: boolean;
  changeHiddenModalCallback: () => void;
  toolName: string;
  appStore?: any;
}

type State = {
  name: string;
  keywords: KeywordType[];
  pre_defined: boolean;
  new_sources: boolean;
  source_groups: SourceGroupsType[];
  keywordType: { title: string; value: number };
  keyword: string;
  emptyKeyword: boolean;
  selectSources: SourceType[];
  sending: boolean;

  emptyName: boolean;
  //   emptyKeywords: boolean;
  emptySelect: boolean;
};

@inject("appStore")
@observer
class CreateFeedModal extends Component<IModal, State> {
  state = {
    name: "",
    keywords: [],
    pre_defined: false,
    new_sources: false,
    source_groups: [],
    keywordType: keywordsOptions[0],
    keyword: "",
    emptyKeyword: false,
    selectSources: [],
    sending: false,

    emptyName: false,
    // emptyKeywords: false,
    emptySelect: false,
  };

  componentDidUpdate(prevProps: IModal, prevState: State) {
    if (prevProps.hidden && !this.props.hidden && this.props.appStore.sourceId) {
      const { sources } = this.props.appStore;
      this.setState({
        selectSources: [sources.find((source: SourceType) => source.id === this.props.appStore.sourceId)],
      });
    }
    if (prevProps.hidden === true && this.props.hidden === false) {
      this.props.appStore.openModal();
    }
  }

  selectAllSources = (value: any) => {
    const { sources } = this.props.appStore;
    this.setState({ selectSources: sources.map((source: SourceType) => ({ ...source, value: source.id })) }, () => {});
  };

  enterName = ({ target: { value } }: { target: { value: string } }) => {
    if (this.state.emptyName) this.setState({ emptyName: false });
    this.setState({ name: value });
  };

  enterKeyword = ({ target: { value } }: { target: { value: string } }) => {
    if (this.state.emptyKeyword) this.setState({ emptyKeyword: false });
    this.setState({ keyword: value });
  };

  deleteKeyword = (index: number) => {
    const { keywords } = this.state;
    this.setState({ keywords: [...keywords.slice(0, index), ...keywords.slice(index + 1, keywords.length)] });
  };

  changeTypeKeyword = (keywordTypeValue: number) => {
    this.setState({ keywordType: keywordsOptions[keywordTypeValue] });
  };

  getIconType = (type: Equality, style?: React.CSSProperties) => {
    switch (type) {
      case Equality.strictEqual:
        return <StrictEqualIcon style={style} />;
      case Equality.equal:
        return <EqualIcon style={style} />;
      case Equality.notEqual:
        return <NotEqualIcon style={style} />;
    }
  };

  getColorType = (type: Equality) => {
    switch (type) {
      case Equality.strictEqual:
        return "light-blue";
      case Equality.equal:
        return "green";
      case Equality.notEqual:
        return "orange";
    }
  };

  addKeyword = () => {
    const { keywords, keyword, keywordType } = this.state;
    if (!keyword) {
      this.setState({ emptyKeyword: true });
      return;
    }
    // if (this.state.emptyKeywords) {
    //   this.setState({ emptyKeywords: false });
    // }
    this.setState({ keywords: [...keywords, { name: keyword, type: keywordType.type }] }, () => {
      this.setState({ keyword: "", keywordType: keywordsOptions[0] });
    });
  };

  deselectAllSources = (value: any) => {
    this.setState({ selectSources: [] });
  };

  chooseSource = (selected: number[]) => {
    const { sources } = this.props.appStore;
    // console.log(selected);
    if (this.state.emptySelect) {
      this.setState({ emptySelect: false });
    }
    this.setState({
      selectSources:
        selected.length > 0 ? selected.map((id) => sources.find((source: SourceType) => source.id === id)) : [],
    });
  };

  chooseSourceGroup = (selected: number[]) => {
    const { sourceGroups } = this.props.appStore;
    // console.log(selected);
    if (this.state.emptySelect) {
      this.setState({ emptySelect: false });
    }
    this.setState({
      source_groups:
        selected.length > 0
          ? selected.map((id) => sourceGroups.find((source: SourceGroupsType) => source.id === id))
          : [],
    });
  };

  changeNewSourcesCheck = (value: any) => {
    // console.log(value);
    this.setState({ new_sources: value });
  };

  saveFeed = () => {
    const { keywords, selectSources, source_groups, new_sources, name } = this.state;
    const { getAllFeeds } = this.props.appStore;
    const { changeHiddenModalCallback } = this.props;
    let words = keywords.length > 0 ? this.convertArrayWordsToString(keywords) : "";
    // console.log(words);
    if (!this.checkFilling()) {
      this.setState({ sending: true }, () => {
        addFeed({
          name,
          new_sources,
          words,
          source_groups,
          sources: selectSources.map((source: SourceType) => ({
            link: source.link,
            type: "telegram",
            id: source.id,
          })),
          pre_defined: false,
        })
          .then((json) => {
            getAllFeeds();
            changeHiddenModalCallback();
            this.setState({
              sending: false,
              name: "",
              keywords: [],
              pre_defined: false,
              new_sources: false,
              source_groups: [],
              keywordType: keywordsOptions[0],
              keyword: "",
              emptyKeyword: false,
              selectSources: this.props.appStore.sources,
            });
            // console.log("saveFeedSuccess", json);
          })
          .catch((error) => {
            // console.log("saveFeedError", error);
            this.setState({ sending: false });
          });
      });
    } else {
      if (name === "") {
        this.setState({ emptyName: true });
      }
      //   if (keywords.length === 0) {
      //     this.setState({ emptyKeywords: true });
      //   }
      if (selectSources.length === 0 && source_groups.length === 0) {
        this.setState({ emptySelect: true });
      }
    }
  };

  convertArrayWordsToString = (wordsArray: KeywordType[]) => {
    const wordFormatter = (keyword: KeywordType) => {
      switch (keyword.type) {
        case Equality.strictEqual:
          return `"${keyword.name}"\r\n`;
        case Equality.equal:
          return `${keyword.name}\r\n`;
        case Equality.notEqual:
          return `!${keyword.name}\r\n`;
      }
    };

    const reducer = (accumulator: string, currentValue: KeywordType) => {
      return `${accumulator}${wordFormatter(currentValue)}`;
    };

    let words = wordsArray.reduce(reducer, "");
    return words;
    // return words.slice(0, words.length - 2)
  };

  checkFilling = () => {
    const { keywords, selectSources, source_groups, name } = this.state;
    if (
      name === "" ||
      //  keywords.length === 0 ||
      (selectSources.length === 0 && source_groups.length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { hidden, changeHiddenModalCallback, toolName } = this.props;
    const {
      keywordType,
      keywords,
      keyword,
      emptyKeyword,
      selectSources,
      source_groups,
      new_sources,
      name,
      sending,
      emptyName,
      //   emptyKeywords,
      emptySelect,
    } = this.state;

    return (
      <FullscreenModal
        visible={!hidden}
        onClose={() => {
          if (!sending) {
            changeHiddenModalCallback();
            this.props.appStore.closeModal();
          }
        }}>
        <FullscreenModal.Close />
        <FullscreenModal.Back>{`Go back`}</FullscreenModal.Back>
        <FullscreenModal.Header title="Create Feed" />
        <FullscreenModal.Body>
          <div style={{ position: "relative", overflowY: "scroll", display: "flex", flex: 1 }}>
            <Flex justifyContent={"center"} flex={1}>
              <Box w={"403px"} pt={8}>
                <Text color={"#333333"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"}>
                  {"Feed Name"}
                </Text>
                <Box mt={2} w={"403px"}>
                  <Tooltip theme="warning">
                    <Tooltip.Trigger>
                      <Input onChange={this.enterName} size="l" w={"403px"} state={emptyName ? "invalid" : "normal"}>
                        <Input.Value placeholder="Feed Name" value={name} />
                      </Input>
                      {emptyName && <Tooltip.Popper>{"You must fill in the feed name"}</Tooltip.Popper>}
                    </Tooltip.Trigger>
                  </Tooltip>
                </Box>

                <Flex alignItems={"center"} mt={5}>
                  <Text color={"#333333"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"} mr={2}>
                    {"Keywords"}
                  </Text>
                </Flex>
                <Text mt={1} color={"#757575"} fontSize={"12px"} lineHeight={"20px"} fontWeight={400}>
                  {"Leave empty if you want to get all messages from sources."}
                </Text>
                <Flex mt={3} alignItems={"center"}>
                  <Select onChange={this.changeTypeKeyword} value={keywordType.value} placeholder="default" size="l">
                    <Select.Trigger mr={2} wMin={113} wMax={113}>
                      <Select.Trigger.Addon>{this.getIconType(keywordType.type)}</Select.Trigger.Addon>
                      <Select.Trigger.Text>{keywordType.title}</Select.Trigger.Text>
                    </Select.Trigger>
                    <Select.Menu hMax={180}>
                      {keywordsOptions.map((keywordTypeItem) => (
                        <Select.Option key={keywordTypeItem.value} value={keywordTypeItem.value}>
                          {this.getIconType(keywordTypeItem.type, { marginRight: 8 })}
                          <Select.OptionTitle>{keywordTypeItem.title}</Select.OptionTitle>
                        </Select.Option>
                      ))}
                    </Select.Menu>
                  </Select>

                  <Input
                    onChange={this.enterKeyword}
                    size="l"
                    state={
                      emptyKeyword
                        ? //  || emptyKeywords
                          "invalid"
                        : "normal"
                    }
                    mr={4}>
                    <Input.Value
                      onKeyUp={(event: any) => {
                        if (event.key === "Enter") this.addKeyword();
                      }}
                      value={keyword}
                      placeholder="Keyword"
                    />
                  </Input>

                  <Link onClick={this.addKeyword}>
                    <Link.Addon>
                      <MathPlusXS />
                    </Link.Addon>
                    <Link.Text>{"Add"}</Link.Text>
                  </Link>
                </Flex>
                <Flex mt={3} flexWrap={true}>
                  {keywords.map((keyword: KeywordType, index: number) => (
                    <Tag
                      key={keyword.name + "_keyword_" + index}
                      size="m"
                      theme={this.getColorType(keyword.type)}
                      use="primary"
                      mr={2}
                      mb={2}>
                      <Tag.Addon>{this.getIconType(keyword.type)}</Tag.Addon>
                      <Tag.Text>{keyword.name}</Tag.Text>
                      <Tag.Close onClick={() => this.deleteKeyword(index)} />
                    </Tag>
                  ))}
                </Flex>
                <Flex alignItems={"center"} mt={4}>
                  <Text color={"#333333"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"} mr={2}>
                    {"Sources"}
                  </Text>
                </Flex>
                <Flex mt={2}>
                  <Box mt={2} w={"403px"}>
                    <Tooltip theme="warning">
                      <Tooltip.Trigger>
                        <Select
                          onChange={this.chooseSource}
                          placeholder="Select Sources"
                          multiselect
                          value={selectSources.map((item: any) => item.id)}
                          state={emptySelect ? "invalid" : "normal"}>
                          <Select.Trigger size="l" flex={1} w={"403px"}>
                            <Select.Trigger.Text>
                              {selectSources.length > 0
                                ? selectSources.reduce(
                                    (prevItem: any, currentItem: any) =>
                                      `${prevItem}${prevItem && ","} ${currentItem.name}`,
                                    ""
                                  )
                                : ""}
                            </Select.Trigger.Text>
                          </Select.Trigger>
                          <Select.Menu>
                            {this.props.appStore?.sources.map((option: SourceType) => (
                              <Select.OptionCheckbox value={option.id} key={option.id}>
                                {option.name}
                              </Select.OptionCheckbox>
                            ))}
                          </Select.Menu>
                        </Select>
                      </Tooltip.Trigger>
                      {emptySelect && (
                        <Tooltip.Popper>{"You must choose one source or one source group"}</Tooltip.Popper>
                      )}
                    </Tooltip>
                  </Box>
                </Flex>
                <Flex alignItems={"center"} mt={5}>
                  <Text color={"#333333"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"} mr={2}>
                    {"Source Groups"}
                  </Text>
                </Flex>
                <Flex mt={2}>
                  <Box mt={2} w={"403px"}>
                    <Tooltip theme="warning">
                      <Tooltip.Trigger>
                        <Select
                          // onVisibleChange={() => {
                          //   console.log("lol");
                          //   this.props.appStore.openSelect();
                          // }}
                          onChange={this.chooseSourceGroup}
                          placeholder="Select Source Groups"
                          multiselect
                          value={source_groups.map((item: any) => item.id)}
                          state={emptySelect ? "invalid" : "normal"}>
                          <Select.Trigger size="l" flex={1} w={"403px"}>
                            <Select.Trigger.Text>
                              {source_groups.length > 0
                                ? source_groups.reduce(
                                    (prevItem: any, currentItem: any) =>
                                      `${prevItem}${prevItem && ","} ${currentItem.name}`,
                                    ""
                                  )
                                : ""}
                            </Select.Trigger.Text>
                          </Select.Trigger>
                          <Select.Menu>
                            {this.props.appStore?.sourceGroups.map((option: SourceGroupsType) => (
                              <Select.OptionCheckbox value={option.id} key={option.id}>
                                {option.name}
                              </Select.OptionCheckbox>
                            ))}
                          </Select.Menu>
                        </Select>
                      </Tooltip.Trigger>
                      {emptySelect && (
                        <Tooltip.Popper>{"You must choose one source or one source group"}</Tooltip.Popper>
                      )}
                    </Tooltip>
                  </Box>
                </Flex>
                <Checkbox size="xl" state="normal" mt={7}>
                  <Checkbox.Value onChange={this.changeNewSourcesCheck} checked={new_sources} />
                  <Checkbox.Text color={"#333333"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"} mt={"2px"}>
                    Add any further created sources to this feed automatically.
                  </Checkbox.Text>
                </Checkbox>
              </Box>
            </Flex>
          </div>
        </FullscreenModal.Body>
        <FullscreenModal.Footer>
          <Flex justifyContent={"center"} alignItems={"center"} flex={1} h={"56px"}>
            <Button onClick={this.saveFeed} loading={sending} size="l" theme={undefined} use="primary" mr={3}>
              Save Feed
            </Button>
            <Button
              onClick={() => {
                if (!sending) {
                  changeHiddenModalCallback();
                  this.props.appStore.closeModal();
                }
              }}
              size="l"
              theme={undefined}
              disabled={sending}
              use="secondary">
              Cancel
            </Button>
          </Flex>
        </FullscreenModal.Footer>
      </FullscreenModal>
    );
  }
}

export default CreateFeedModal;
