import { observable, action } from "mobx";
import { getAllFavoriteMessages, getAllFeeds, getAllSource, getSourceGroup, getSourceInfo } from "../api/request";
import { FeedType, MessageType, SourceGroupsType, SourcesInfoType, SourceType } from "../utils/types";

type PageInfoType = {
  clientHeight: number;
  clientWidth: number;
  offsetLeft: number;
  offsetTop: number;
  scrollLeft: number;
  scrollTop: number;
};

class appStore {
  @observable modalVisible: boolean = false;
  @observable oldHeight: number = 0;

  @action resizeWindow = () => {
    window.SM.client("getPageInfo")
      .then((json: PageInfoType) => {
        this.oldHeight = json.clientHeight;
        window.SM.client("resizeWindow", { height: json.clientHeight });
        setInterval(() => {
          if (!this.modalVisible) {
            let height = document.getElementById("heightControl")?.offsetHeight ?? json.clientHeight + 0;
            // console.log("height", height);
            if (json.clientHeight >= height && this.oldHeight !== height) {
              this.oldHeight = height;
              //   console.log("min");
              window.SM.client("resizeWindow", { height: json.clientHeight, width: 1138 });
            } else if (json.clientHeight < height && this.oldHeight !== height) {
              //   console.log("middle");
              this.oldHeight = height;
              window.SM.client("resizeWindow", { height: height + 50, width: 1138 });
            }
          }
        }, 500);
        // console.log("getPageInfo", json);
      })
      .catch((error: any) => {
        // console.log("getPageInfo", error);
      });
  };

  @action openModal = (withoutScroll?: boolean) => {
    this.modalVisible = true;
    window?.SM?.client("getPageInfo").then((json: PageInfoType) => {
      this.oldHeight = json.clientHeight;
      // if(!withoutScroll){
      window?.SM?.client("scrollTo", { top: 0 });
      // }
      window?.SM?.client("resizeWindow", { height: this.checkHeight(json.clientHeight - 150), width: 1138 });
    });
  };

  checkHeight = (height: number) => {
    if (height < 800) {
      return 800;
    } else {
      return height;
    }
  };

  @action openSelect = () => {
    window?.SM?.client("getPageInfo").then((json: PageInfoType) => {
      this.oldHeight = json.clientHeight;
      window?.SM?.client("resizeWindow", {
        height: json.clientHeight - 150 < 800 ? 800 : json.clientHeight - 150,
        width: 1138,
      });
    });
  };

  @action closeModal = () => {
    this.modalVisible = false;
  };

  @observable feeds: FeedType[] = [];
  @observable feedsLoading: boolean = true;
  @observable sourceGroups: SourceGroupsType[] = [];
  @observable sources: SourceType[] = [];
  @observable sourcesLoading: boolean = true;
  @observable favoritesMessages: MessageType[] = [];
  @observable favoritesMessagesLoading: boolean = true;
  @observable sourcesInfo: SourcesInfoType = {
    available_sources: 0,
    purchased_sources: 0,
    used_sources: 0,
  };
  // source id for adding in feed after create source
  // need clear after saving feed
  @observable sourceId?: number = undefined;

  @action getSourceInfo = () => {
    getSourceInfo()
      .then((json) => {
        // console.log("getSourceInfoSuccess", json);
        this.sourcesInfo = json;
      })
      .catch((error) => {
        // console.log("getSourceInfoError", error);
      });
  };

  @action setSourceId = (id?: number) => {
    this.sourceId = id;
  };

  @action getSourceGroup = () => {
    return new Promise((resolve, reject) => {
      getSourceGroup()
        .then((json) => {
          //   console.log("getSourceGroupSuccess", json);
          this.sourceGroups = json.results;
          resolve(json);
        })
        .catch((error) => {
          reject();
          //   console.log("getSourceGroupError", error);
        });
    });
  };

  @action getAllFeeds = () => {
    return new Promise((resolve, reject) => {
      getAllFeeds()
        .then((json) => {
          //   console.log("getAllFeedsSuccess", json);
          this.feeds = json.results;
          this.feedsLoading = false;
          resolve(json);
        })
        .catch((error) => {
          //   console.log("getAllFeedsError", error);
          reject(error);
        });
    });
  };

  @action getAllSource = () => {
    return new Promise((resolve, reject) => {
      getAllSource()
        .then((json) => {
          //   console.log("getAllSourceSuccess", json);
          this.sources = json.results;
          if (this.sourcesLoading) this.sourcesLoading = false;
          resolve(json);
        })
        .catch((error) => {
          //   console.log("getAllSourceError", error);
          reject(error);
        });
    });
  };

  @action getAllFavoriteMessages = () => {
    return new Promise((resolve, reject) => {
      getAllFavoriteMessages()
        .then((json) => {
          //   console.log("getAllFavoriteMessagesSuccess", json);
          this.favoritesMessages = json.results;
          if (this.favoritesMessagesLoading) this.favoritesMessagesLoading = false;
          resolve(json.results);
        })
        .catch((error) => {
          //   console.log("getAllFavoriteMessagesError", error);
          if (this.favoritesMessagesLoading) this.favoritesMessagesLoading = false;
          reject(error);
        });
    });
  };

  @action setAllFavoriteMessagesLoading = (value: boolean) => (this.favoritesMessagesLoading = value);

  @action clearFeed = () => {
    this.feeds = [];
  };
}

export default new appStore();
