import React, { Component } from "react";
import FullscreenModal from "@semcore/fullscreen-modal";
import Button from "@semcore/button";
import { Flex, Box } from "@semcore/ui/flex-box";
import Input from "@semcore/input";
import Link from "@semcore/ui/link";
import style from "./style.module.scss";
import { Text } from "@semcore/typography";
import Notice from "@semcore/notice";
import { addSource, checkChannel, editSource, getSource } from "../../api/request";
import { inject, observer } from "mobx-react";
import { appStore } from "../../stores";
import { TableLoader } from "../../components";
import Tooltip from "@semcore/tooltip";
import { ActionTypes } from "./createSourceModalTypes";
import { SourceType } from "../../utils/types";

interface IModal {
  hidden: boolean;
  changeHiddenModalCallback: (hidden: boolean) => void;
  toolName: string;
  appStore?: typeof appStore;
  addChannelToFeedCallback: (id: number) => void;
  createFeedWithChannelCallback: (id: number) => void;
  editSource?: SourceType;
}

type State = {
  link: string;
  sending: boolean;
  channelUndefined: boolean;
  emptyLink: boolean;
};

@inject("appStore")
@observer
class EditSourceModal extends Component<IModal, State> {
  state = {
    link: "",
    mistake: false,
    sending: false,
    channelUndefined: false,
    emptyLink: false,
  };

  source?: SourceType;

  componentDidUpdate(prevProps: IModal, prevState: State) {
    if (prevProps.hidden && !this.props.hidden && this.props.editSource) {
      this.setState({ sending: true }, () => {
        this.props.editSource && this.getSource(this.props.editSource.id);
      });
    }
    if (prevProps.hidden === true && this.props.hidden === false) {
      this.props.appStore?.openModal();
    }
  }

  getSource = (id: number) => {
    getSource(id)
      .then((json: SourceType) => {
        this.source = json;
        this.setState({ link: json.link, sending: false });
      })
      .catch(() => {
        this.setState({ sending: false });
        this.props.changeHiddenModalCallback(true);
      });
  };

  addSource = (link: string, name: string, description: string = "", actionType: ActionTypes) => {
    if (this.source) {
      editSource({
        id: this.source.id,
        name,
        link,
        language: "NONE",
        store_days: 28,
        type: "telegram",
        description,
      })
        .then((json) => {
          // console.log("addSourceSuccess", json);
          // console.log("addSourceSuccess", json);
          this.props?.appStore?.getAllSource().then(() => {
            this.source = undefined;
            this.setState({ sending: false, link: "" });
            switch (actionType) {
              case ActionTypes.save:
                this.saveChannel();
                break;
              case ActionTypes.saveCreate:
                this.saveChannelCreateFeed(json);
                break;
              case ActionTypes.saveEdit:
                this.saveChannelEditFeed(json);
                break;
            }
          });
        })
        .catch(() => {
          this.setState({ sending: false });
        });
    }
  };

  saveChannel = () => {
    this.props.changeHiddenModalCallback(true);
  };

  saveChannelEditFeed = (source: SourceType) => {
    this.props.addChannelToFeedCallback(source.id);
  };

  saveChannelCreateFeed = (source: SourceType) => {
    this.props.createFeedWithChannelCallback(source.id);
  };

  checkChannel = (actionType: ActionTypes) => {
    const { link } = this.state;
    if (link) {
      this.setState({ sending: true }, () => {
        checkChannel(link)
          .then((json) => {
            // console.log("checkChannelSuccess", json);
            if (json) {
              this.addSource(link, json.title, json.description, actionType);
            } else {
              this.setState({ sending: false, channelUndefined: true });
            }
          })
          .catch((error) => {
            // console.log("checkChannelError", error);
            this.setState({ sending: false });
          });
      });
    } else {
      this.setState({ emptyLink: true });
    }
  };

  onChangeLink = ({ target: { value } }: { target: { value: string } }) => {
    if (this.state.channelUndefined) this.setState({ channelUndefined: false });
    if (this.state.emptyLink) this.setState({ emptyLink: false });
    this.setState({ link: value.replace("https://", "").replace("t.me/", "") });
  };

  render() {
    const { toolName, hidden, changeHiddenModalCallback } = this.props;
    const { link, channelUndefined, sending, emptyLink } = this.state;
    return (
      <FullscreenModal
        visible={!hidden}
        onClose={() => {
          changeHiddenModalCallback(true);
          this.props.appStore?.closeModal();
        }}>
        {sending && <TableLoader />}
        <FullscreenModal.Close />
        <FullscreenModal.Back>{`Go back`}</FullscreenModal.Back>
        <FullscreenModal.Header title="Edit Channel" />
        <FullscreenModal.Body>
          <Box flex={1}>
            {/* <Notice
                            hidden={false}
                            style={{
                                padding: '10px 16px 9px 30px',
                                borderTop: 'none',
                                borderRight: 'none',
                                borderLeft: 'none',
                            }}
                        >
                            <Notice.Content>
                                {"Info text about Subscriprion Allowance. May be buttons to buy more"}
                            </Notice.Content>
                        </Notice> */}
            <Flex justifyContent={"center"} flex={1}>
              <Box w={"403px"} pt={8}>
                <Text color={"#333333"} fontSize={"14px"} fontWeight={400} lineHeight={"20px"}>
                  {"Telegram Link"}
                </Text>
                <Box mt={2} wMin={"403px"}>
                  <Tooltip w={"403px"} theme="warning">
                    <Tooltip.Trigger w={"403px"}>
                      <Input
                        onChange={this.onChangeLink}
                        w={"403px"}
                        size="l"
                        state={emptyLink || channelUndefined ? "invalid" : "normal"}>
                        <Input.Addon tag={Text} color={link ? "#333" : "gray70"} pr={"0px"}>
                          {"t.me/"}
                        </Input.Addon>
                        <Input.Value pl={0} value={link} placeholder="channel name" />
                      </Input>
                    </Tooltip.Trigger>
                    {channelUndefined && <Tooltip.Popper>{`Channel ${link} undefined`}</Tooltip.Popper>}
                    {emptyLink && <Tooltip.Popper>{`You must fill in the channel name`}</Tooltip.Popper>}
                  </Tooltip>
                </Box>
                <Box mt={4}>
                  <Link onClick={() => this.checkChannel(ActionTypes.saveCreate)} disabled={!link}>
                    {"Save and create new feed"}
                  </Link>
                </Box>
                <Box mt={4}>
                  <Link onClick={() => this.checkChannel(ActionTypes.saveEdit)} disabled={!link}>
                    {"Save and add to existing feed"}
                  </Link>
                </Box>
              </Box>
            </Flex>
          </Box>
        </FullscreenModal.Body>
        <FullscreenModal.Footer>
          <Flex justifyContent={"center"} alignItems={"center"} flex={1} h={"56px"}>
            <Button onClick={() => this.checkChannel(ActionTypes.save)} size="l" theme={undefined} use="primary" mr={3}>
              Save Channel
            </Button>
            <Button
              onClick={() => {
                changeHiddenModalCallback(true);
                this.props.appStore?.closeModal();
              }}
              size="l"
              theme={undefined}
              use="secondary">
              Cancel
            </Button>
          </Flex>
        </FullscreenModal.Footer>
      </FullscreenModal>
    );
  }
}
export default EditSourceModal;
