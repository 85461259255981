import React, { Component } from "react";

interface IPage {
  style?: any;
  children: any;
}

export default class Page extends Component<IPage> {
  render() {
    const { children, style } = this.props;
    return (
      <div
        style={{
          ...style,
          height: "95vh",
          minWidth: 1138,
          maxWidth: 1138,
          marginLeft: "auto",
          marginRight: "auto",
        }}>
        {children}
      </div>
    );
  }
}
