import React, { Component, FC } from "react";
import { Flex, Box } from "@semcore/ui/flex-box";
import { Text } from "@semcore/typography";
import Button from "@semcore/ui/button";
import MathPlusXS from "@semcore/icon/lib/MathPlus/xs";
import Table from "@semcore/ui/table";
import Link from "@semcore/ui/link";
import style from "./style.module.scss";
import EditS from "@semcore/icon/lib/Edit/s";
import TrashS from "@semcore/icon/lib/Trash/s";
import { TableLoader } from "../../../components";
import LinkExternalXS from "@semcore/icon/lib/LinkExternal/xs";
import { AddChannelToFeed, CreateFeedModal, CreateSourceModal, EditFeedModal, EditSourceModal } from "../../../modals";
import { inject, observer } from "mobx-react";
import { appStore } from "../../../stores";
import { SourceType } from "../../../utils/types";
import { deleteSource } from "../../../api/request";
import Skeleton from "@semcore/skeleton";
import { NoData } from "@semcore/widget-empty";
import { startUrl } from "../../../utils/constants";
import SpinContainer from "@semcore/spin-container";
import Sticky from "@semcore/sticky";
import Spin from "@semcore/spin";
import { Sort } from "../../start/startPageTypes";
import Modal from "@semcore/modal";
import Card from "@semcore/ui/card";

interface IBanner {
  title: string;
  description: string;
  onClick: () => void;
}
const Banner: FC<IBanner> = ({ title, description, onClick }) => {
  return (
    <div className={style.bannerCont} style={{ marginTop: 16 }}>
      {/* <div
                className={style.bannerImage}
                style={{ backgroundImage: `url(${require('../../../assets/images/reportMoved.png')})` }}
            /> */}
      <Box ml={4} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        <Text mt={3} color={"#333333"} fontSize={"16px"} lineHeight={"24px"} fontWeight={700}>
          {title}
        </Text>
        <Text mt={1} color={"#333333"} fontSize={"12px"} lineHeight={"18px"} fontWeight={400}>
          {description}
        </Text>
        <Button onClick={onClick} mt={3} size="m" theme="success" use="primary">
          {"Buy"}
        </Button>
      </Box>
    </div>
  );
};

declare global {
  interface Window {
    SM?: any;
  }
}

interface ITab {
  appStore?: typeof appStore;
}

type State = {
  hidden: boolean;
  sending: boolean;
  hiddenAddChannelToFeed: boolean;
  createFeedModalHidden: boolean;
  editSource?: SourceType;
  editSourceModalHidden: boolean;
  sort: Sort;
  sortField: string;
  confirmModalVisible: boolean;
};

@inject("appStore")
@observer
class SourcesTab extends Component<ITab, State> {
  state = {
    hidden: true,
    sending: false,
    hiddenAddChannelToFeed: true,
    createFeedModalHidden: true,
    editSource: undefined,
    editSourceModalHidden: true,
    sort: Sort.asc,
    sortField: "name",
    confirmModalVisible: false,
  };

  channelIdDeleted: number | null = null;

  componentDidMount() {
    this.props?.appStore?.getAllSource();
  }

  changeHiddenModal = (hidden: boolean) => this.setState({ hidden });

  deleteSource = (sourceId: number) => {
    this.setState({ sending: true }, () => {
      deleteSource(sourceId)
        .then(() => {
          this.props.appStore?.getSourceInfo();
          this.props?.appStore?.getAllSource().finally(() => {
            this.setState({ sending: false });
          });
        })
        .catch(() => {
          this.setState({ sending: false });
        });
    });
  };

  renderRows = (source: SourceType, index: number) => {
    return (
      <Table.Row key={source.id + "source" + index}>
        <Table.Cell w={"332px"} valign={"center"} pt={"8px"} pb={"8px"}>
          <Flex alignItems={"center"}>
            <div
              className={style.cellIcon}
              style={{ backgroundImage: `url(${require("../../../assets/images/cellIcon.png")})` }}
            />
            <Text fontWeight={400} fontSize={"14px"} lineHeight={"20px"}>
              {source.name}
            </Text>
          </Flex>
        </Table.Cell>
        <Table.Cell valign={"center"} pt={"8px"} pb={"8px"}>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Link onClick={() => window.open(`${startUrl}${source.link}`)}>
              <Link.Text>{source.link}</Link.Text>
              <Link.Addon>
                {/* <Button size="m" theme="muted" use="tertiary"  > */}
                <LinkExternalXS color={"#A6B0B3"} />
                {/* </Button> */}
              </Link.Addon>
            </Link>
            <Flex>
              <Button onClick={() => this.editSource(source)} mr={2} size="m" theme="muted" use="tertiary">
                <Button.Addon>
                  <EditS color={"#A6B0B3"} />
                </Button.Addon>
              </Button>
              <Button
                // onClick={() => this.deleteSource(source.id)}
                onClick={() => {
                  this.props.appStore?.openModal();
                  this.setState({ confirmModalVisible: true });
                  this.channelIdDeleted = source.id;
                }}
                size="m"
                theme="muted"
                use="tertiary">
                <Button.Addon>
                  <TrashS color={"#A6B0B3"} />
                </Button.Addon>
              </Button>
            </Flex>
          </Flex>
        </Table.Cell>
      </Table.Row>
    );
  };

  editSource = (source: SourceType) => {
    this.setState({ editSource: source }, () => {
      this.changeHiddenEditSourceModal(false);
    });
  };

  changeHiddenEditSourceModal = (hidden: boolean) => {
    if (hidden) {
      this.setState({ editSource: undefined, editSourceModalHidden: hidden });
    } else {
      this.setState({ editSourceModalHidden: hidden });
    }
  };

  changeHiddenAddChannelToFeed = (hidden: boolean) => {
    if (hidden) {
      this.props.appStore?.setSourceId();
    }
    this.setState({ hiddenAddChannelToFeed: hidden });
  };

  addChannelToFeedCallback = (id: number) => {
    this.props.appStore?.setSourceId(id);
    this.changeHiddenModal(true);
    this.changeHiddenAddChannelToFeed(false);
  };

  changeHiddenCreateFeedModal = (hidden: boolean) => {
    if (hidden) {
      this.props.appStore?.setSourceId();
    }
    this.setState({ createFeedModalHidden: hidden });
  };

  createFeedWithChannelCallback = (id: number) => {
    this.props.appStore?.setSourceId(id);
    this.changeHiddenModal(true);
    this.changeHiddenCreateFeedModal(false);
  };

  changeSort = (sortField: string) => {
    this.setState({
      sort: sortField !== this.state.sortField ? Sort.asc : this.state.sort === Sort.asc ? Sort.desc : Sort.asc,
      sortField,
    });
  };

  sortArray = (data: SourceType[]) => {
    let sortArrayRender = JSON.parse(JSON.stringify(data));
    sortArrayRender.sort((a: any, b: any) => {
      if (this.state.sort === Sort.desc) {
        if (
          a[this.state.sortField] &&
          a[this.state.sortField].toLowerCase() < b[this.state.sortField] &&
          b[this.state.sortField].toLowerCase()
        ) {
          return 1;
        } else {
          return -1;
        }
      } else if (this.state.sort === Sort.asc) {
        if (
          a[this.state.sortField] &&
          a[this.state.sortField].toLowerCase() > b[this.state.sortField] &&
          b[this.state.sortField].toLowerCase()
        ) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    });
    return sortArrayRender;
  };

  buyChannel = () => {
    window.SM.client("requestInAppPurchase");
    // console.log("work");
  };

  closeConfirm = () => {
    this.props.appStore?.closeModal();
    this.setState({ confirmModalVisible: false });
    this.channelIdDeleted = null;
  };

  render() {
    const {
      confirmModalVisible,
      sortField,
      sort,
      hidden,
      sending,
      hiddenAddChannelToFeed,
      createFeedModalHidden,
      editSourceModalHidden,
      editSource,
    } = this.state;

    return (
      <Box style={{ position: "relative" }}>
        <SpinContainer loading={sending}>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Text fontSize={"25px"} lineHeight={"28px"} fontWeight={500}>
              {"Channels"}
            </Text>
            <Link onClick={() => this.changeHiddenModal(false)} size={200} lineHeight={"18px"}>
              <Link.Addon>
                <MathPlusXS />
              </Link.Addon>
              <Link.Text>Add Channel</Link.Text>
            </Link>
          </Flex>
          {this.props?.appStore?.sourcesInfo.used_sources !== 0 && (
            <Banner
              title={"Pop up your channels allowance"}
              description={`You are currently using ${this.props?.appStore?.sourcesInfo.used_sources} of ${this.props?.appStore?.sourcesInfo.purchased_sources} available source slots. You can always buy more.`}
              // description={`You are currently using
              // ${
              //   this.props?.appStore?.sourcesInfo.available_sources && this.props?.appStore?.sourcesInfo.purchased_sources
              //     ? Math.round(
              //         ((this.props?.appStore?.sourcesInfo.purchased_sources -
              //           this.props?.appStore?.sourcesInfo.available_sources) *
              //           100) /
              //           this.props?.appStore?.sourcesInfo.purchased_sources
              //       )
              //     : "0"
              // }%
              //  of available source slots. You can always buy more.`}
              // description={`You have ${this.props?.appStore?.sourcesInfo.available_sources} out of ${this.props?.appStore?.sourcesInfo.purchased_sources} sources available. You can always buy more.`}
              onClick={this.buyChannel}
            />
          )}
          {!this.props?.appStore?.sourcesLoading && this.props.appStore?.sources.length === 0 && (
            <Card my={5} pt={4}>
              <NoData type="other-data" description="Channels is empty" />
            </Card>
          )}
          {(this.props?.appStore?.sourcesLoading ||
            (!this.props?.appStore?.sourcesLoading && this.props.appStore?.sources.length !== 0)) && (
            <Table mt={5}>
              <Table.Head>
                <Table.Row>
                  <Table.CellHead
                    onClick={() => this.changeSort("name")}
                    sorting={sortField === "name" ? sort : "asc"}
                    active={sortField === "name"}
                    valign="middle"
                    w={"388px"}
                    wMax={"388px"}>
                    <span>{"Name"}</span>
                  </Table.CellHead>
                  <Table.CellHead
                    onClick={() => this.changeSort("link")}
                    active={sortField === "link"}
                    sorting={sortField === "link" ? sort : "asc"}
                    valign="middle">
                    <span>{"Link"}</span>
                  </Table.CellHead>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {this.props.appStore?.sources && this.sortArray(this.props.appStore?.sources).map(this.renderRows)}
                {this.props?.appStore?.sourcesLoading &&
                  Array(5)
                    .fill("")
                    .map((item, index) => (
                      <Table.Row key={"skeleton" + index}>
                        <Table.Cell w={"332px"} valign={"center"} pt={"8px"} pb={"8px"}>
                          <Flex alignItems={"center"}>
                            <Skeleton height={"15px"}>
                              <Skeleton.Text y="6" width="80%" />
                            </Skeleton>
                          </Flex>
                        </Table.Cell>
                        <Table.Cell valign={"center"} pt={"8px"} pb={"8px"}>
                          <Flex justifyContent={"space-between"} alignItems={"center"}>
                            <Skeleton height={"15px"}>
                              <Skeleton.Text y="6" width="50%" />
                            </Skeleton>
                          </Flex>
                        </Table.Cell>
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
          )}
          <Modal visible={confirmModalVisible} onClose={this.closeConfirm}>
            <Modal.Overlay style={{ background: "transparent" }}>
              <Modal.Window style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)" }}>
                <Text tag="h2" size={400} mb={4}>
                  Confirmation to delete channel:
                </Text>
                <Text size={200} mb={3} tag="p">
                  Are you sure you want to delete this channel?
                </Text>
                <Button
                  use="primary"
                  theme="danger"
                  onClick={() => {
                    this.channelIdDeleted && this.deleteSource(this.channelIdDeleted);
                    this.closeConfirm();
                  }}>
                  Delete
                </Button>
                <Button ml={2} onClick={this.closeConfirm}>
                  Cancel
                </Button>
              </Modal.Window>
            </Modal.Overlay>
          </Modal>
          <EditSourceModal
            toolName={"Channels"}
            editSource={editSource}
            hidden={editSourceModalHidden}
            changeHiddenModalCallback={this.changeHiddenEditSourceModal}
            addChannelToFeedCallback={this.addChannelToFeedCallback}
            createFeedWithChannelCallback={this.createFeedWithChannelCallback}
          />
          <CreateSourceModal
            toolName={"Channels"}
            hidden={hidden}
            changeHiddenModalCallback={this.changeHiddenModal}
            addChannelToFeedCallback={this.addChannelToFeedCallback}
            createFeedWithChannelCallback={this.createFeedWithChannelCallback}
          />
          <AddChannelToFeed
            toolName={"Channels"}
            hidden={hiddenAddChannelToFeed}
            changeHiddenModalCallback={this.changeHiddenAddChannelToFeed}
          />
          <CreateFeedModal
            toolName={"toolName"}
            hidden={createFeedModalHidden}
            changeHiddenModalCallback={() => this.changeHiddenCreateFeedModal(true)}
          />
          <SpinContainer.Overlay style={{ alignItems: "flex-start" }}>
            <Spin tag={Sticky} top="200px" size="xxl" />
          </SpinContainer.Overlay>
        </SpinContainer>
      </Box>
    );
  }
}

export default SourcesTab;
