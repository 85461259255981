import React from "react";
import { Switch, Route } from "react-router-dom";
import { MainPage, EmptyPage, LoadingPage, StartPage } from "../pages";

const MainRoute = () => {
  return (
    <Switch>
      <Route exact path="/" component={LoadingPage} />
      <Route exact path="/Start" component={StartPage} />
      <Route exact path="/Main/:tabKey/:feedId" component={MainPage} />
      <Route component={EmptyPage} />
    </Switch>
  );
};

export default MainRoute;
