export type ActionCardType = {
    tooltipContent: string,
    title: string,
    description: string,
    onClickAdd: () => void,
    onClickSee?: () => void
}

export enum Sort {
    asc = 'asc',
    desc = 'desc'
}