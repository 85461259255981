import React, { Component } from 'react';
import './assets/css/App.css';
import { MainRoute } from './routes';

class App extends Component {

  // constructor(props: any) {
  //   super(props)

  //   declare global {
  //     interface Window {
  //       SM: any;
  //     }
  //   }
  // }
  render() {
    return (
      <MainRoute />
    )
  }
}

export default App;
