import React, { Component } from "react";
import { Flex, Box } from "@semcore/ui/flex-box";
import { Text } from "@semcore/typography";
import resolveColor from "@semcore/utils/lib/color";
import Card from "@semcore/ui/card";
import Button from "@semcore/ui/button";
import MathPlusXS from "@semcore/icon/lib/MathPlus/xs";
import Table from "@semcore/ui/table";
import Link from "@semcore/ui/link";
import Badge from "@semcore/ui/badge";
import style from "./style.module.scss";
import EditS from "@semcore/icon/lib/Edit/s";
import FavoriteS from "@semcore/icon/lib/Favorite/s";
import TrashS from "@semcore/icon/lib/Trash/s";
import { DateRangePicker } from "@semcore/date-picker";
import StackGrid from "react-stack-grid";
import { FeedCard, FeedCardSkeleton, TableLoader } from "../../../components";
import { inject, observer } from "mobx-react";
import { appStore } from "../../../stores";
import moment from "moment";
import { deleteMessageFromFavorite } from "../../../api/request";
import { Equality, FeedInMessageType, KeywordType, MessageType, VideoType } from "../../../utils/types";
import { NoData } from "@semcore/widget-empty";
import { startUrl } from "../../../utils/constants";
import SpinContainer from "@semcore/spin-container";
import Sticky from "@semcore/sticky";
import Spin from "@semcore/spin";
import ArrowLeftM from "@semcore/icon/lib/ArrowLeft/m";
import ArrowRightM from "@semcore/icon/lib/ArrowRight/m";
import ReactPlayer from "react-player";
import Modal from "@semcore/modal";

interface ITab {
  appStore?: typeof appStore;
}

type State = {
  currentPage: number;
  sending: boolean;
  dateRange: Date[];
  imageModalVisible: boolean;
  videoModalVisible: boolean;
  images: string[];
  videoUrl: string;
  currentImage: number;
};

@inject("appStore")
@observer
class FavouritesTab extends Component<ITab, State> {
  state: State = {
    currentPage: 1,
    sending: false,
    dateRange: [],
    imageModalVisible: false,
    videoModalVisible: false,
    images: [],
    currentImage: 0,
    videoUrl: "",
  };

  componentDidMount() {
    this.props?.appStore?.getAllFavoriteMessages();
  }

  deleteMessageFromFavorite = (messageId: number) => {
    this.setState({ sending: true }, () => {
      deleteMessageFromFavorite(messageId).then(() => {
        this.props.appStore?.getAllFavoriteMessages().finally(() => {
          this.setState({ sending: false });
        });
      });
    });
  };

  getSortMessages = () => {
    const { dateRange } = this.state;
    let messages = this.props.appStore?.favoritesMessages;
    if (messages) {
      if (dateRange.length > 0 && dateRange[0]) {
        return messages.filter((message: MessageType) => {
          // console.log(moment(message.date).unix(), moment(dateRange[0]).unix(), moment(dateRange[1]).unix())
          if (
            moment(message.date).unix() >= moment(dateRange[0]).unix() &&
            moment(message.date).unix() <= moment(moment(dateRange[1]).format().split("T")[0] + "T23:59:59").unix()
          ) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        return messages;
      }
    } else {
      return [];
    }
  };

  changeRange = (dateRange: Date[]) => {
    // console.log("dateRange", dateRange);
    this.setState({ dateRange });
  };

  findAndSelectionKeyword = (message: MessageType) => {
    let text: string = " " + message.text + " ";
    let keywords: KeywordType[] | undefined = [];

    [
      ...text.matchAll(
        /\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]/gm
      ),
    ].forEach((matchArray: RegExpMatchArray) => {
      // console.log(matchArray);
      // console.log(matchArray[0]);
      if (matchArray.index) text = text.slice(0, matchArray.index + 2) + " " + text.slice(matchArray.index + 2);
      // if (matchArray.index) text = text.substring(0, matchArray.index) + "lol" + text.substring(matchArray.index);
    });

    message?.feed_set?.forEach((feed: FeedInMessageType) => {
      if (feed.words) {
        feed.words
          .split("\r\n")
          // .map((word) => word.replaceAll('"', ""))
          // .map((word) => word.replaceAll("/", ""))
          .forEach((word: string) => {
            switch (word[0]) {
              case '"':
                keywords?.push({
                  name: word.replaceAll('"', ""),
                  type: Equality.strictEqual,
                });
                break;
              case "!":
                keywords?.push({
                  name: word,
                  type: Equality.notEqual,
                });
                break;
              default:
                keywords?.push({
                  name: word,
                  type: Equality.equal,
                });
            }
          });
      }
    });
    // console.log("keywords", keywords);

    if (keywords && keywords.length > 0) {
      keywords.forEach((keyword: KeywordType) => {
        switch (keyword.type) {
          case Equality.strictEqual:
            text = text.replaceAll(
              ` ${keyword.name} `,
              ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b> `
            );
            text = text.replaceAll(
              ` ${keyword.name}.`,
              ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b>.`
            );
            text = text.replaceAll(
              ` ${keyword.name}?`,
              ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b>?`
            );
            text = text.replaceAll(
              ` ${keyword.name},`,
              ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b>,`
            );
            text = text.replaceAll(
              ` ${keyword.name}!`,
              ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b>!`
            );
            // text = text.replaceAll(
            //   ` ${keyword.name} `,
            //   ` <b style="background-color: rgba(43, 148, 225, 0.15); color: #2B94E1">${keyword.name}</b> `
            // );
            break;
          case Equality.equal:
            text = text.replaceAll(
              `${keyword.name}`,
              `<b style="background-color: rgba(79, 174, 51, 0.15);color: #4FAE33">${keyword.name}</b>`
            );
            break;
          case Equality.notEqual:
            text = text.replaceAll(
              `${keyword.name}`,
              `<b style="background-color: rgba(255, 127, 0, 0.15);color: #FF7F00">${keyword.name}</b>`
            );
            break;
        }
      });
    }

    return text;
  };

  openImageViewer = (data: string[], imageIndex: number) => {
    this.props?.appStore?.openModal(true);
    this.setState({ imageModalVisible: true, images: data, currentImage: imageIndex });
  };

  openVideoViewer = (url: string) => {
    this.props?.appStore?.openModal(true);
    this.setState({ videoModalVisible: true, videoUrl: url });
  };

  nextImage = () => {
    this.setState({
      currentImage: this.state.currentImage === this.state.images.length - 1 ? 0 : ++this.state.currentImage,
    });
  };
  prevImage = () => {
    this.setState({
      currentImage: this.state.currentImage === 0 ? this.state.images.length - 1 : --this.state.currentImage,
    });
  };

  render() {
    const { currentPage, sending, imageModalVisible, videoModalVisible } = this.state;
    return (
      <Box style={{ position: "relative" }}>
        <SpinContainer loading={sending}>
          <Flex justifyContent={"space-between"}>
            <Box>
              <Flex alignItems={"center"}>
                <Text fontSize={"25px"} lineHeight={"28px"} fontWeight={500}>
                  {"Favourites"}
                </Text>
              </Flex>
            </Box>
            <DateRangePicker onChange={this.changeRange} />
          </Flex>
          {!sending && !this.props.appStore?.feedsLoading && this.getSortMessages().length === 0 && (
            <Card my={5} pt={4}>
              <NoData type="other-data" description="Favourites is empty" />
            </Card>
          )}
          <StackGrid columnWidth={444} style={{ marginTop: 16 }} gutterWidth={16} gutterHeight={16}>
            {this.props.appStore?.feedsLoading &&
              Array(4)
                .fill("")
                .map(() => <FeedCardSkeleton />)}
            {!this.props.appStore?.feedsLoading &&
              this.getSortMessages()
                .slice(currentPage == 1 ? 0 : (currentPage - 1) * 100, currentPage * 100)
                .map((message: MessageType, index) => {
                  let images: string[] = [];
                  let videos: VideoType[] = [];
                  if (message.files.length > 0) {
                    message.files.forEach((anyUrl) => {
                      if (/_tgparser_thumbnail.jpg/.test(anyUrl.file)) {
                        videos.push({
                          preview: anyUrl.file,
                          url: anyUrl.file.replace("_tgparser_thumbnail.jpg", ""),
                        });
                      } else {
                        if (/\.(gif|jpg|jpeg|tiff|png)$/i.test(anyUrl.file)) {
                          images.push(anyUrl.file);
                        }
                      }
                      // if (/\.(gif|jpg|jpeg|tiff|png)$/i.test(anyUrl.file)) {
                      //   images.push(anyUrl.file);
                      // } else {
                      //   videos.push(anyUrl.file);
                      // }
                    });
                  }

                  return (
                    <FeedCard
                      messageLink={`${startUrl}${message?.source.link}/${message.internal_id}`}
                      key={message.id + "message" + index}
                      title={message?.source.name ?? "Not title"}
                      description={message?.source?.description}
                      message={this.findAndSelectionKeyword(message)}
                      link={`${startUrl}${message?.source.link}`}
                      date={moment(message?.date).format("ll HH:MM")}
                      favorite={message.favorites}
                      onClickAddToFav={() => {}}
                      onClickRemoveFromFav={() => this.deleteMessageFromFavorite(message.id)}
                      images={images}
                      videos={videos}
                      onClickImage={this.openImageViewer}
                      onClickVideo={this.openVideoViewer}
                    />
                  );
                })}
          </StackGrid>
          <SpinContainer.Overlay style={{ alignItems: "flex-start" }}>
            <Spin tag={Sticky} top="200px" size="xxl" />
          </SpinContainer.Overlay>
        </SpinContainer>
        <Modal
          w={"90vw"}
          h={"90vh"}
          visible={videoModalVisible}
          onClose={() => {
            this.props?.appStore?.closeModal();
            this.setState({ videoModalVisible: false, videoUrl: "" });
          }}>
          <Modal.Overlay style={{ background: "transparent" }}>
            <Modal.Window w={"90vw"} h={"90vh"} style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)" }}>
              <ReactPlayer controls={true} url={this.state.videoUrl} width={"100%"} height={"100%"} />
            </Modal.Window>
            {/* {this.state.videoUrl !== "" && ( */}
            {/* )} */}
          </Modal.Overlay>
        </Modal>

        <Modal
          w={"90vw"}
          h={"90vh"}
          visible={imageModalVisible}
          onClose={() => {
            this.props?.appStore?.closeModal();
            this.setState({ imageModalVisible: false, images: [], currentImage: 0 });
          }}>
          <Modal.Overlay style={{ background: "transparent" }}>
            <Modal.Window w={"90vw"} h={"90vh"} style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)" }}>
              {imageModalVisible && this.state.images.length > 0 && this.state.currentImage !== null && (
                <div
                  style={{
                    backgroundImage: `url(${
                      this.state.images[this.state.currentImage !== null ? this.state.currentImage : 0]
                    })`,
                    width: "100%",
                    height: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                />
              )}
              <div
                onClick={this.prevImage}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 25,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  left: 40,
                  top: "50%",
                  backgroundColor: "#ccc",
                }}>
                <ArrowLeftM />
              </div>
              <div
                onClick={this.nextImage}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 25,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  right: 40,
                  top: "50%",
                  backgroundColor: "#ccc",
                }}>
                <ArrowRightM />
              </div>
            </Modal.Window>
          </Modal.Overlay>
        </Modal>
      </Box>
    );
  }
}

export default FavouritesTab;
