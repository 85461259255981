// import moment from 'moment'
import Api from "./network";
import { AddFeedParamsType, AddSourceParamsType, EditFeedParamsType, EditSourceParamsType } from "./requestsTypes";
import moment from "moment";

const api = new Api();

export const getSourceGroup = async () => {
  return await api.get({ route: "source_group" });
};
export const getSourceInfo = async () => {
  return await api.get({ route: "user/available_sources/" });
};

export const getAllFeeds = async () => {
  return await api.get({ route: "feed" });
};

export const getFeed = async (id: number) => {
  return await api.get({ route: `feed/${id}` });
};

export const addFeed = async (body: AddFeedParamsType) => {
  return await api.post({ route: "feed/", body });
};

export const editFeed = async (body: EditFeedParamsType) => {
  return await api.put({ route: `feed/${body.id}/`, body });
};

export const deleteFeed = async (id: number) => {
  return await api.delete({ route: `feed/${id}` });
};

export const getAllSource = async () => {
  return await api.get({ route: "source" });
};

export const getSource = async (id: number) => {
  return await api.get({ route: `source/${id}` });
};

export const editSource = async (body: EditSourceParamsType) => {
  return await api.put({ route: `source/${body.id}/`, body });
};

export const deleteSource = async (id: number) => {
  return await api.delete({ route: `source/${id}` });
};

export const addSource = async (body: AddSourceParamsType) => {
  return await api.post({ route: "source/", body });
};

export const getAllFeedsMessages = async (id: number, offset: number, dateRange: Date[]) => {
  let route = `feed_message/${id}?limit=50&offset=${offset}`;
  if (dateRange.length > 0 && dateRange[0] !== undefined) {
    route += `&date_from=${moment(dateRange[0]).format().split("T")[0] + "T00:00:00"}&date_to=${
      moment(dateRange[1]).format().split("T")[0] + "T23:59:59"
    }`;
  }
  return await api.get({ route });
};

export const getAllFavoriteMessages = async () => {
  return await api.get({ route: `feed_message/favorites` });
};

export const addMessageToFavorite = async (id: number) => {
  return await api.get({ route: `feed_message/favorites/${id}` });
};

export const deleteMessageFromFavorite = async (id: number) => {
  return await api.get({ route: `feed_message/favorites/${id}/remove` });
};

export const checkChannel = async (link: string) => {
  return await api.get({ route: `source/check/${link}` });
};
